/* eslint-disable react/prop-types */
import React from 'react';

import FileInput from '../FileInput';

import s from './style.module.scss';

const FilePicker = (props) => {
  const {
    fileName,
    handleFileInputChange,
    error,
    errorMessage,
    placeHolder,
    fileOpenerText,
    fileTypes,
  } = props;
  return (
    <div className={s.container}>
      <FileInput
        fileTypes={fileTypes}
        placeHolder={placeHolder}
        fileName={fileName}
        error={error}
        errorMessage={errorMessage}
        fileOpenerText={fileOpenerText}
        handleChange={e => handleFileInputChange(e)}
      />
    </div>
  );
};

export default FilePicker;
