/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import UserForm from 'components/UserForm';


import * as userService from '../../services/user';

import LoadingBox from '../../components/LoadingBox';

import './style.scss';

const PersonalInfo = (props) => {
  const [loading, setLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [updateErrorMessage, setUpdateErrorMessage] = useState(
    'Erreur durant la mise à jour de vos informations',
  );
  const [showForm, setShowForm] = useState(true);
  const {
    updateUser,
  } = props.userService; // eslint-disable-line react/destructuring-assignment

  const onFormSubmit = async (values) => {
    setLoading(true);
    setShowForm(false);
    try {
      let nextPage;
      const { state } = props.location;
      if (state) {
        nextPage = state.nextPage; // eslint-disable-line prefer-destructuring
      }
      values.password = 'null';
      await updateUser(values);
      props.history.push(nextPage || '/personalPage');
    } catch (err) {
      setLoading(false);
      setUpdateError(true);
      setUpdateErrorMessage(err.message);
    }
  };

  const hideLoader = () => {
    setShowForm(true);
    setUpdateSuccess(false);
    setUpdateError(false);
    setUpdateErrorMessage('');
  };

  const handleCancel = () => {
    props.history.goBack();
  };

  return (
    <>
      <UserForm
        type="personalInfo"
        hidden={!showForm}
        onSubmit={onFormSubmit}
        handleCancel={handleCancel}
        showErrors
      />
      {!showForm && (
      <div className="loading-container">
        <LoadingBox
          loading={loading}
          updateErrorMessage
          success={updateSuccess}
          error={updateError}
          showErrorButton
          handleClick={hideLoader}
          errorMessage={updateErrorMessage}
        />
      </div>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  userService: bindActionCreators(userService, dispatch),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(PersonalInfo),
);
