/* eslint-disable react/prop-types */

import React from 'react';
import s from './style.module.scss';

const OrderDropdown = (props) => {
  const {
    date, price, status, children, statusStyle, statusColor, isOpen, getId,
  } = props;
  const display = isOpen ? 'block' : 'none';
  return (
    <div className={s.container}>
      <ul className={s.container__header}>
        <li>{date}</li>
        <li>{price}</li>
        <li style={{ fontStyle: statusStyle, color: statusColor }}>{status}</li>
        <li
          onClick={getId}
          onKeyPress={getId}
          role="presentation"
        >
            Détails
        </li>
      </ul>
      <div
        style={{ display }}
        className={s.container__body}
      >
        {children}
      </div>
    </div>
  );
};

OrderDropdown.defaultProps = {
  statusStyle: 'italic',
  statusColor: 'black',
};

export default OrderDropdown;
