import React from 'react';
import PropTypes from 'prop-types';
import { FaTrashAlt } from 'react-icons/fa';

import s from './style.module.scss';

const IconButton = (props) => {
  const { handleClick } = props;
  return (
    <div
      className={s.container}
      onClick={handleClick}
      onKeyPress={handleClick}
      tabIndex="0"
      role="button"
    >
      <FaTrashAlt />
    </div>
  );
};

IconButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default IconButton;
