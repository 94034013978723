/* eslint-disable react/prop-types */
import React from 'react';

import './style.scss';

const renderFieldType = (type, input, placeholder, options, textarea) => {
  switch (type) {
    case 'textarea':
      return <textarea {...input} placeholder={placeholder} type={type} />;
    case 'select':
      return (
        <select {...input} placeholder={placeholder} type={type}>
          {options && options.map((each, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <option value={each.id} key={index}>{each.name}</option>
          ))}

        </select>
      );

    default:
      return <input autoComplete="nope" {...input} placeholder={placeholder} type={type} />;
  }
};

const RenderField = (props) => {
  const {
    input,
    label,
    placeholder,
    type,
    textarea,
    meta: { touched, error, warning },
    options,
    showErrors,
  } = props;
  const showErr = touched || showErrors;
  return (
    <div className="signUp--input-container">
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={label}>{label}</label>
      <div className="signUp--input-wrapper">
        {renderFieldType(type, input, placeholder, options, textarea)}
        {showErr
                    && ((error && <span>{error}</span>)
                    || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default RenderField;
