/* eslint-disable react/prop-types */
import React from 'react';

import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';

import TextButton from '../TextButton';
import RenderField from '../RenderField';

import { validate } from '../../helpers/validate';

// eslint-disable-next-line import/no-mutable-exports
const ForgetPasswordForm = (props) => {
  const {
    handleSubmit, pristine, reset, submitting, hidden,
  } = props;

  return (
    <>
      <form className={hidden ? 'form-hidden' : ''} onSubmit={handleSubmit}>
        <Field
          name="mail"
          type="mail"
          component={RenderField}
          placeholder="jeandupont@gmail.com"
          label="E-mail"
        />
        <div className="signUp-buttons--row">
          <TextButton
            disabled={pristine || submitting}
            onClick={reset}
            text="Retour"
            handleClick={() => props.history.goBack()}
            color="pink"
          />
          <TextButton
            text="Recevoir un nouveau mot-de-passe"
            type="submit"
            handleClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default withRouter(
  reduxForm({
    form: 'forgetPassword',
    validate,
  })(ForgetPasswordForm),
);
