/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IoIosRocket } from 'react-icons/io';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import TextButton from '../../components/TextButton';
import TestimonialCard from '../../components/TestimonialCard';
import Layout from '../../components/Layout';

import testimonials from '../../resources/data/testimonials.json';

import './style.scss';

class Home extends React.Component {
  onReservationClick() {
    const { history } = this.props;
    history.push('/editions');
  }

  render() {
    const { device } = this.props;
    const isDesktop = device === 'desktop';
    return (
      <>
        <div className="home-container">
          <Layout
            cover="/assets/images/covers/home.jpg"
            isHome
          >
            <div className="home-header">
              <div className="home-header-content">
                <div className="home-header-heading">
                  Boostez
                  <br />
                  votre activité
                </div>
                <IoIosRocket
                  size={150}
                  color="#2095a9"
                />
                <div className="home-header-subHeader">
                Développez vos ventes régionales grâce à
                  <div className="home-header-img-container">
                    <img src="/assets/images/proximag/logo.jpg" alt="Proximag" />
                  </div>
                </div>
                <div className="home-header-button">
                  <TextButton
                    text="Faites votre publicité"
                    handleClick={() => this.onReservationClick()}
                  />
                </div>
              </div>
            </div>
          </Layout>
          <div className="home-description">
            <div className="home-description-content">
              <div className="home-description-heading">
                Pourquoi travailler avec nous ?
              </div>
              <div
                className={
                  isDesktop ? 'home-description-desktop-row-wraper' : null
                }
              >
                <div className="home-description-rows">
                  <div className="home-description-row">
                    <div className="home-description-row-text">
                      Le Proximag, édité par L’Avenir, est
                      {' '}
                      <b>
                        distribué partout en Wallonie auprès de 1.4 million de
                        foyers
                      </b>
                      . Ceux-ci le reçoivent toutes les semaines ou tous les 15
                      jours, selon l’édition. Avec un contenu propre à chacune
                      des 33 éditions, Proximag est
                      {' '}
                      <b>
                        votre média de proximité qui vous connecte directement
                      </b>
                      {' '}
                      aux lecteurs, au cœur de leur région.
                    </div>
                  </div>
                  <div className="home-description-row">
                    <div className="home-description-row-text">
                      Une grande partie du contenu est réservé à l’information
                      locale, une
                      {' '}
                      <b>offre inégalée en presse gratuite. </b>
                      Votre annonce est placée dans un
                      {' '}
                      <b>
                        contexte crédible
                      </b>
                      {' '}
                      et favorable afin de vous assurer le meilleur impact en
                      terme de communication.
                    </div>
                  </div>
                  {true ? ( // eslint-disable-line no-constant-condition
                    <div className="home-description-row">
                      <div className="home-description-row-text">
                        <div>
                          Le Proximag est le toutes-boîtes
                          {' '}
                          <b>le plus lu et préféré en Wallonie</b>
                          . Il est
                          apprécié et reconnu pour son format, son contenu et
                          son utilité. Ses lecteurs lui attribuent un score de
                          satisfaction de 7,15/10. Grâce à Proximag, optimisez
                          vos forces et développez votre commerce dans votre
                          réseau.
                        </div>
                        <div className="subinfo">
                          Source : enquête Listen décembre 2018 (échantillon :
                          892 personnes)
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {isDesktop ? (
                  <div className="home-description-proximag-container">
                    <div className="home-description-proximag-content">
                      <div className="home-description-proximag-mag">
                        <img src="/assets/images/proximag/1.png" alt="proximag" />
                      </div>
                      <div className="home-description-proximag-button">
                        <a href="http://proximagservices.lavenir.net/VirtualPaper.aspx" target="blank">Voir le Proximag</a>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="home-tutorial-container" id="tutorial">
            <div className="home-tutorial-title">Comment ça marche ?</div>
            <div className="home-tutorial-wrapper">
              <div className="home-tutorial-video">
                <iframe src="https://www.youtube.com/embed/ZbjreOT3Fq8" frameBorder="0" title="tutorial" />
              </div>
            </div>
          </div>
          <div className="home-middle">
            <div className="home-middle-content">
              <div className="home-middle-heading">
                Plus d&apos;impact, plus de résultat
              </div>
              <div className="home-middle-button">
                <TextButton
                  text="Faites votre publicité"
                  handleClick={() => this.onReservationClick()}
                />
              </div>
            </div>
          </div>
          <div className="home-client-counter">
            <div className="home-client-counter-content">
              <div className="home-client-counter-row">
                <div className="text">
                  <b>70% </b>
                  Taux de lecture
                </div>
              </div>
              <div className="home-client-counter-row">
                <div className="text">
                  <b>83% </b>
                  Taux de notoriété
                </div>
              </div>
              <div className="home-client-counter-row">
                <div className="text">
                  <b>71% </b>
                  Taux d’interactions entre lecteurs et annonceurs
                </div>
              </div>
            </div>
          </div>
          <AliceCarousel
            autoPlay
            autoPlayInterval={20000}
            buttonsDisabled
          >
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <TestimonialCard testimonial={testimonial} />
              </div>
            ))}
          </AliceCarousel>

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  device: state.device,
});

export default withRouter(connect(mapStateToProps)(Home));
