function getBase64(file) {
  return new Promise(((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function data() {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  }));
}

export default getBase64;
