/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FaUser } from 'react-icons/fa';
import TextButton from '../../components/TextButton';

import './style.scss';

class NavBar extends React.Component {
  handleClick(destination, nextPage) {
    const { location, history } = this.props;
    if (location.pathname !== destination) {
      history.push(destination, { nextPage });
    }
  }

  render() {
    const { user } = this.props;
    return (
      <div className="navbar-container">
        <div
          className="navbar-item"
          onClick={() => this.handleClick('/editions')}
          onKeyPress={() => this.handleClick('/editions')}
          role="button"
          tabIndex="0"
        >
          Réserver
        </div>
        <div
          className="navbar-item"
          onClick={() => this.handleClick('/faq')}
          onKeyPress={() => this.handleClick('/faq')}
          role="button"
          tabIndex="0"
        >
          FAQ
        </div>
        {user.isConnected
          ? (
            <div
              className="navbar-item"
              onClick={() => {
                this.handleClick('/personalPage');
              }}
              onKeyPress={() => {
                this.handleClick('/personalPage');
              }}
              role="button"
              tabIndex="0"
              style={{ textTransform: 'capitalize' }}
            >
              <FaUser style={{ marginRight: 5 }} />
              {`${user.firstName} ${user.lastName}`}
            </div>
          )
          : (
            <TextButton
              text="Espace personnel"
              handleClick={() => { this.handleClick('/connection'); }}
            />
          )
      }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(NavBar));
