/* eslint-disable react/prop-types */
import React from 'react';
import { FaRegSquare, FaRegCheckSquare } from 'react-icons/fa';

import s from './style.module.scss';

const CheckedInput = (props) => {
  const {
    name, checked, onClick, showInfo, moreInfo,
  } = props;
  if (name !== null) {
    return (
      <div
        onClick={onClick}
        onKeyPress={onClick}
        className={s.container}
        role="button"
        tabIndex="0"
      >
        <div className={s.container__check}>
          {checked ? <FaRegCheckSquare /> : <FaRegSquare />}
        </div>
        <div className={s.container__title}>
          {name}
          {' '}
          {moreInfo && (
          <span onClick={showInfo}>(voir plus)</span>
          )}
        </div>
      </div>
    );
  } return (
    null
  );
};

export default CheckedInput;
