import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class StripeElementWrapper extends PureComponent {
  state = {
    focused: false,
    empty: true,
    error: false,
    errorMessage: '',
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleChange = (changeObj) => {
    this.setState({
      error: !!changeObj.error,
      errorMessage: changeObj.error ? changeObj.error.message : '',
      empty: changeObj.empty,
    });
  };

  render() {
    const { component: Component, label, supportedCountries } = this.props;
    const { error, errorMessage } = this.state;

    return (
      <div className="field">
        <label
          className={`stripe_label ${error ? 'stripe_label_haserror' : ''}`}
        >
          {label}
        </label>
        <div className="input-container stripe-input-container">
          <Component
            className={error ? 'StripeElement-err' : ''}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            supportedCountries={supportedCountries}
          />
          {error && (
            <div className="text-input-error-placeholder">
              <div className="text-input-error">{errorMessage}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

StripeElementWrapper.propsTypes = {
  component: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
};

export default StripeElementWrapper;
