/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import SideBar from '../../components/SideBar';

const Header = (props) => {
  const { device } = props;
  const isMobile = ['mobile', 'tablet'].includes(device);
  return (
    <div>
      {isMobile ? (
        <>
          <SideBar />
          <HeaderMobile />
        </>
      ) : (
        <HeaderDesktop />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  device: state.device,
});

export default withRouter(connect(mapStateToProps)(Header));
