/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import LoadingBox from 'components/LoadingBox';
import * as userService from '../../services/user';

import UserForm from '../../components/UserForm';
import './style.scss';
import Layout from '../../components/Layout';

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState(
    "Erreur durant l'inscription",
  );
  const [showForm, setShowForm] = useState(true);

  const onFormSubmit = async (values) => {
    const { signUpUser } = props.userService;
    setLoading(true);
    setShowForm(false);
    try {
      await signUpUser(values);
      setLoading(false);
      setSignUpSuccess(true);
      props.history.push('/user/validate');
    } catch (err) {
      setLoading(false);
      setSignUpError(true);
      setSignUpErrorMessage(err.message);
    }
  };

  const hideLoader = () => {
    setShowForm(true);
    setSignUpSuccess(false);
    setSignUpError(false);
    setSignUpErrorMessage('');
  };

  const handleCancel = () => {
    props.history.push('/connection');
  };

  return (
    <Layout
      cover="/assets/images/covers/reservation.jpg"
      title="Réservation"
      isHome
    >
      <div className="signUp-container">
        <div className="signUp-container--header">Inscription</div>
        <UserForm hidden={!showForm} onSubmit={onFormSubmit} handleCancel={handleCancel} />
        {!showForm && (
        <div className="loading-container">
          <LoadingBox
            loading={loading}
            signUpErrorMessage
            success={signUpSuccess}
            error={signUpError}
            showErrorButton
            handleClick={hideLoader}
            errorMessage={signUpErrorMessage}
          />
        </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  userService: bindActionCreators(userService, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SignUp),
);
