/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import LoadingBox from 'components/LoadingBox';
import * as currentOrderActions from '../../actions/currentOrderActions';
import * as orderService from '../../services/order';
import 'moment/locale/fr';

import CurrentOrder from '../CurrentOrder';
import CheckedInput from '../../components/CheckedInput';
import Modal from '../../components/Modal';
import Layout from '../../components/Layout';
import CGV from '../CGV';

import './style.scss';

class orderRecap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generalConditionsAccepted: false, // eslint-disable-line react/no-unused-state
      display: false,
    };
  }


  componentWillMount() {
    const { user, history } = this.props;
    const {
      edition,
      selectedEditionDates,
      format,
    } = this.props.currentOrder;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.currentOrderActions.setGeneralConditionsAccepted(false);
    if (!user.isConnected) {
      history.push('/connection', { nextPage: '/orderRecap' });
    }
    if (edition === undefined || selectedEditionDates === undefined) {
      history.push('/editions', { nextPage: '/orderRecap' });
    } else if (format === undefined) {
      history.push('/format', { nextPage: '/orderRecap' });
    }
  }

  saveOrder = async () => {
    const { orderData, materialFiles } = this.props.currentOrder;
    return this.props.orderService.addOrder(orderData, materialFiles);
  }

  showModal() {
    const { display } = this.state;
    this.setState({
      display: !display,
    });
  }

  render() {
    const {
      savingOrder,
      edition,
      selectedEditionDates,
      format,
      generalConditionsAccepted,
    } = this.props.currentOrder; // eslint-disable-line react/destructuring-assignment
    const { currentOrderActions } = this.props; // eslint-disable-line no-shadow

    const { display } = this.state;

    return (
      <>
        <Layout
          cover="/assets/images/covers/reservation.jpg"
          title="Récapitulatif de votre commande"
        >
          <div className="order-recap-container">
            {savingOrder && (
            <Modal style={{
              maxWidth: '600px', transform: 'translate(-50%, -50%)', left: '50%', top: '50%',
            }}
            >
              <div className="warning-modal-content loading-modal">
                <div className="title">Sauvegarde de votre commande en cours</div>
                <LoadingBox loading />
              </div>
            </Modal>
            )}
            <div className="order-recap-main">
              <div className="order-items-container">
                <div className="order-item">
                  {edition
                    ? (
                      <div className="order-item-content">
                        <div className="text">{edition}</div>
                      </div>
                    )
                    : null}
                </div>
                <div className="order-item">
                  {selectedEditionDates.length > 0
                    ? (
                      <div className="order-item-content">
                        <div className="item-wraper">
                          {selectedEditionDates.map((date, i) => (
                            <div key={i} className="item-date">
                              <div className="text">
                                {moment.unix(date).format('dddd D MMMM YYYY')}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                    : null}
                </div>
                <div className="order-item">
                  {format ? (
                    <div className="order-item-content">
                      <div className="text">{format.description}</div>
                    </div>
                  ) : (
                    null
                  )}
                </div>
                <div className="order-check-boxes">
                  <CheckedInput
                    checked={generalConditionsAccepted}
                    onClick={() => {
                      currentOrderActions.setGeneralConditionsAccepted(
                        !generalConditionsAccepted,
                      );
                      currentOrderActions.clearCurrentOrderError();
                    }}
                    textLink
                    handleLinkClick={() => console.log('accepted')}
                    name="J'accepte les conditions générales de vente"
                    moreInfo
                    showInfo={() => this.showModal()}
                  />
                </div>
              </div>
              {/* Order & Help */}
              <div className="order-help-container">
                <div className="order-help-order">
                  <CurrentOrder beforeClickHandler={this.saveOrder} />
                </div>
              </div>
            </div>
          </div>
        </Layout>
        <CGV display={display} onClickOutside={() => this.showModal()} />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
    orderService: bindActionCreators(orderService, dispatch),
  };
}

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(orderRecap),
);
