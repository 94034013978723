/* eslint-disable react/prop-types */
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import s from './style.module.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.clickHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.clickHandler);
  }

  handleHeaderClick() {
    const { handleHeaderClick } = this.props;
    handleHeaderClick();
  }

  // eslint-disable-next-line consistent-return
  clickHandler(e) {
    const { onClickOutside } = this.props;
    if (this.node.contains(e.target)) {
      return e.target;
    } if (onClickOutside) {
      onClickOutside();
    }
  }

  render() {
    const {
      children, style, onClickOutside, closeButton,
    } = this.props;
    return (
      <div className={s.container}>
        {/* eslint-disable-next-line no-return-assign */}
        <div className={s.container__content} ref={node => (this.node = node)} style={style}>
          {closeButton && (
          <div
            className={s.container__content__close}
            onClick={onClickOutside}
            onKeyPress={onClickOutside}
            role="button"
            tabIndex="0"
          >
            <FaTimes />
          </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default Modal;
