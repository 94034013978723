/* eslint-disable react/prop-types */
import React from 'react';
import { FaRegSquare, FaRegCheckSquare } from 'react-icons/fa';

import './style.scss';

class ProximagCheckBox extends React.Component {
  handleCheck() {
    const { handleCheck } = this.props;
    handleCheck();
  }

  handleLinkClick() {
    const { handleLinkClick } = this.props;
    handleLinkClick();
  }

  render() {
    const {
      text, checked, centerText, textLink,
    } = this.props;
    return (
      <div className="checkbox-container">
        <div
          onClick={() => (textLink ? null : this.handleCheck())}
          onKeyPress={() => (textLink ? null : this.handleCheck())}
          role="button"
          tabIndex="0"
          className={`checkbox-content ${textLink ? 'textLink' : null} ${
            centerText ? 'centerText' : null
          }`}
        >
          <div
            className={`checkContainer ${centerText ? 'centerText' : null}`}
            onClick={() => this.handleCheck()}
            onKeyPress={() => this.handleCheck()}
            role="button"
            tabIndex="0"
          >
            <div className="checkbox">
              {checked ? <FaRegCheckSquare /> : <FaRegSquare />}
            </div>
          </div>
          <div
            className={`textContainer ${centerText ? 'centerText' : null} 
            ${textLink ? 'textLink' : null}
           ${text.length >= 10 ? 'long' : null}
          `}
            onClick={() => (textLink ? this.handleLinkClick() : this.handleCheck())}
            onKeyPress={() => (textLink ? this.handleLinkClick() : this.handleCheck())}
            role="button"
            tabIndex="0"
          >
            {text}
          </div>
          {/* {centerText ? <div className="placeHolder" /> : null} */}
        </div>
      </div>
    );
  }
}

export default ProximagCheckBox;
