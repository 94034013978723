import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaShoppingCart } from 'react-icons/fa';

import s from './style.module.scss';


const BasketIndicator = (props) => {
  const { device, history, currentOrder } = props; // eslint-disable-line react/prop-types
  const isTablet = device === 'tablet';
  const isMobile = device === 'mobile';

  const redirectTo = (page) => {
    history.push(page);
  };

  const redirectToRightStep = () => {
    if (!currentOrder.edition || currentOrder.selectedEditionDates.length === 0) {
      redirectTo('/editions');
    } else if (!currentOrder.format.size) {
      redirectTo('/format');
    } else if (currentOrder.materialType === undefined) {
      redirectTo('/upload');
    } else {
      redirectTo('/orderRecap');
    }
  };


  const isOrderExisting = () => {
    if (currentOrder.edition) {
      return 1;
    } return 0;
  };

  return (
    <div className={s.container} onClick={redirectToRightStep} role="button" tabIndex="0">
      <FaShoppingCart
        className={s.container__icon}
        size={isMobile ? 20 : isTablet ? 30 : 20} // eslint-disable-line no-nested-ternary
        style={{ marginRight: '2px' }}
      />
      <span style={{ fontSize: 12 }}>
        {isOrderExisting()}
      </span>
    </div>
  );
};

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
  device: state.device,
});

export default withRouter(connect(mapStateToProps)(BasketIndicator));
