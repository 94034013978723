/* eslint-disable react/prop-types */
import React from 'react';

import UploadButton from '../UploadButton';

import './style.scss';

class FileInput extends React.Component {
  style = {
    display: 'flex',
    flexAlign: 'column',
  };

  render() {
    const {
      name,
      placeHolder,
      errorMessage,
      required,
      fileTypes,
      fileName,
      error,
      fileOpenerText,
      handleChange,
    } = this.props;

    return (
      <div className="file-input-container">
        <label className="file-input-label" htmlFor={name}>
          <div className={`file-input-label-content ${error ? 'err' : null}`}>
            <div className="file-input-button">
              <UploadButton text={fileOpenerText} handleClick={() => null} />
            </div>

            <div className="file-input-text">
              {fileName || placeHolder}
            </div>

            <input
              accept={fileTypes}
              type="file"
              placeholder={placeHolder}
              name={name}
              onChange={e => handleChange({
                required,
                name: e.target.name,
                value: e.target.value,
                file: e.target.files[0],
              })
              }
            />
          </div>
        </label>
        <div className="file-input-error-placeholder">
          {error ? (
            <div className="file-input-error">{errorMessage}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default FileInput;
