import React from 'react';
import s from './style.module.scss';

const Layout = (props) => {
  const {
    children, cover, title, isHome, // eslint-disable-line react/prop-types
  } = props;
  return (
    <div className={s.container}>
      <div className={s.container__cover} style={{ backgroundImage: `url("${cover}")` }} />
      {isHome
        ? (
          <div className={s.container__smaller}>
            {children}
          </div>
        )
        : (
          <div className={s.container__larger}>
            <div className={s.container__larger__title}>{title}</div>
            {children}
          </div>
        )
      }
    </div>
  );
};

Layout.defaultProps = {
  isHome: false,
};

export default Layout;
