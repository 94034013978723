import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Layout from '../../components/Layout';

import questions from '../../resources/data/faq.json';

import './style.scss';

class Faq extends React.Component {
  componentDidMount() {
    const questionWrapper = document.querySelectorAll('.faq-question-wrapper');
    const questionAnswer = document.querySelectorAll('.faq-answer-wrapper');
    function isOpen(e) {
      return (
        questionWrapper[e].classList.toggle('faq-question-wrapper--open'),
        questionAnswer[e].classList.toggle('faq-answer-wrapper--open')
      );
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < questionWrapper.length; i++) {
      questionWrapper[i].addEventListener('click', () => (
        isOpen(i)
      ));
    }
  }

  render() {
    return (
      <Layout
        cover="/assets/images/covers/faq.jpg"
        title="FAQ"
      >
        <div className="faq-container">
          {questions.map((q, index) => (
            <div className="faq-wrapper" key={q.question}>
              <div className="faq-question-wrapper">{`${index + 1}. ${q.question}`}</div>
              <div className="faq-answer-wrapper">{q.answer}</div>
            </div>
          ))}
          <div className="faq-tooltip">
            <p>Vous ne trouvez pas de réponse à vos questions ?</p>
            <p>
              Vous pouvez
              {' '}
              <Link to="/#tutorial">regarder ce tutoriel</Link>
              {' '}
              et/ou
              {' '}
              <NavLink to="/contact">nous contacter.</NavLink>
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Faq;
