/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import ValidateAccountForm from '../../components/ValidateAccountForm';
import LoadingBox from '../../components/LoadingBox';
import Layout from '../../components/Layout';

import '../SignUp/style.scss';

import * as userService from '../../services/user';
import * as userActions from '../../actions/userActions';


const ValidateAccount = (props) => {
  const [loading, setLoading] = useState(false);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState(
    'Erreur durant la validation du compte',
  );
  const [user, setUser] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showValidationMessage, setFlagValidationMessage] = useState(true);
  const [validationMessage, setValidationMessage] = useState('Votre code de validation à été envoyé sur votre adresse e-mail');

  const hideLoader = () => {
    setShowForm(true);
    setValidationSuccess(false);
    setValidationError(false);
    setValidationErrorMessage('');
  };

  const onFormSubmit = async (values) => {
    // eslint-disable-next-line no-shadow
    const { history, userActions } = props;

    setLoading(true);
    setShowForm(false);
    try {
      const email = user !== '' ? user : values.email;
      await userService.validateUser(email, values.code);
      setFlagValidationMessage(true);
      setValidationMessage('Votre compte a bien été activé.');
      userActions.setEmail(email);
      history.push('/connection?validationMsg=success');
    } catch (error) {
      setLoading(false);
      setValidationError(true);
      setValidationErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (user === '' && !loaded) {
      if (props.user.mail !== '') {
        setUser(props.user.mail);
      }
      setLoaded(true);
    }
  }, [user, loaded, props.user.mail]);

  return (
    <Layout
      cover="/assets/images/covers/reservation.jpg"
      isHome
    >
      <div className="signUp-container">
        {showValidationMessage && (
          <span className="signUp-container--message">
            {validationMessage}
          </span>
        )}
        <div className="signUp-container--header">Validation de compte</div>
        <ValidateAccountForm hidden={!showForm} onSubmit={onFormSubmit} showMail={user === ''} />
        {!showForm && (
          <div className="loading-container">
            <LoadingBox
              loading={loading}
              validationErrorMessage
              success={validationSuccess}
              error={validationError}
              showErrorButton
              handleClick={hideLoader}
              errorMessage={validationErrorMessage}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

function mapDispatchToProps(dispatch) {
  return {
    userService: bindActionCreators(userService, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ValidateAccount),
);
