import fetch from 'isomorphic-fetch';
import { endpoints } from '../api';

// eslint-disable-next-line import/prefer-default-export
export const contact = async (body) => {
  try {
    const res = await fetch(endpoints.contact, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    // const data = await res.json();
    const data = await res;
    if (!res.ok) throw data;
    return data;
  } catch (error) {
    throw error;
  }
};
