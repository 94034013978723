import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './userReducer';
import deviceReducer from './deviceReducer';
import currentOrderReducer from './currentOrderReducer';

export default combineReducers({
  user: userReducer,
  device: deviceReducer,
  currentOrder: currentOrderReducer,
  form: formReducer,
});
