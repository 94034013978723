import React from 'react';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StripeElementWrapper from 'components/Stripe/StripeElementWrapper';
import LoadingBox from 'components/LoadingBox';
import TextInput from 'components/TextInput';
import CurrentOrder from 'containers/CurrentOrder';
import * as currentOrderActions from '../../actions/currentOrderActions';
import CardSelector from './CardSelector';
import Layout from '../../components/Layout';
import './style.scss';
import * as orderService from '../../services/order';

class Credit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      loading: false,
      success: false,
      name: props.user.isConnected
        ? `${props.user.firstName} ${props.user.lastName}`
        : '',
      nameError: '',
      nameRequired: true,
      nameValid: true,
      email: props.user.mail,
      emailError: '',
      emailRequired: true,
      emailValid: true,
      formSubmitedOnce: false,
    };
  }

  componentWillMount() {
    const { user, history, currentOrder } = this.props;
    if (!user.isConnected) {
      history.push('/connection', { nextPage: '/payment/credit' });
    }
    if (!currentOrder.savedOrder) {
      history.push('/personalPage');
    }
  }

  async componentDidMount() {
    this.props.currentOrderActions.clearCurrentOrderError();
  }

  handleServerResponse = async (response) => {
    const {
      orderService, stripe, currentOrder,
    } = this.props;
    if (response.status === 'requires_action') {
      // Use Stripe.js to handle required card action
      const result = await stripe.handleCardAction(
        response.client_secret,
      );
      if (result.error) {
        throw result.error;
      }
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      const data = await orderService.payOrder({
        id: currentOrder.savedOrder.paymentIntentId,
        paymentMethodId: null,
        orderId: currentOrder.savedOrder.id,
      });
      this.handleServerResponse(data);
    }
    return true;
  }

  handleSubmit = async (fromForm) => {
    const {
      currentOrderActions, orderService, stripe, history, currentOrder,
    } = this.props;
    try {
      if (!this.validateFields()) throw new Error('Erreur dans vos informations');
      this.setState({ loading: true });
      if (this.props.stripe) {
        currentOrderActions.clearCurrentOrderError();
        const {
          paymentMethod,
          error,
        } = await stripe.createPaymentMethod('card', {
          billing_details: {
            name: this.state.name,
            email: this.state.email,
          },
        });
        if (error) {
          throw error;
        }
        // ConfirmOrder
        const data = await orderService.payOrder({
          id: currentOrder.savedOrder.paymentIntentId,
          paymentMethodId: paymentMethod.id,
          orderId: currentOrder.savedOrder.id,
        });
        const resp = await this.handleServerResponse(data);
        // If handleSubmit is called from this component handle redirect here else let currentOrder do it
        if (fromForm && resp) {
          history.push('/personalPage?message=payment_success');
          return true;
        }
        return resp ? true : 'no_redirect';
      }
      throw new Error('Erreur de chargement.');
    } catch (error) {
      this.setState({ loading: false });
      // If handleSubmit is called from this component handle error here else let currentOrder do it
      if (fromForm) {
        currentOrderActions.setCurrentOrderError({ error: true, errorMessage: error.message ? error.message : error });
      } else {
        throw error;
      }
    }
  };

  handleChange = ({ name, value, required }) => {
    const { formSubmitedOnce } = this.state;
    const nameValid = `${name}Valid`;
    const nameErrorMessage = `${name}Error`;
    let valid = true;
    let errorMessage;

    if (required && !value && formSubmitedOnce) {
      valid = false;
      errorMessage = 'ce champs et requis';
    }

    this.setState({ [name]: value });
    this.setState({ [nameValid]: valid });
    this.setState({ [nameErrorMessage]: valid ? '' : errorMessage });
  };

  validateFields = () => {
    const {
      name, nameRequired, email, emailRequired,
    } = this.state;

    const nameValid = nameRequired ? !!name : true;
    const emailValid = emailRequired
      ? !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      : true;

    const nameError = nameValid ? '' : 'ce champ est requis';
    const emailError = email
      ? emailValid
        ? ''
        : 'email non valide'
      : 'ce champ est requis';

    // Toggle form submited once
    const formSubmitedOnce = true;

    // Check form validity
    const formValid = ![nameValid, emailValid].includes(false);
    this.setState({
      nameValid,
      nameError,
      emailValid,
      emailError,
      formSubmitedOnce,
    });

    return formValid;
  };

  render() {
    const {
      error,
      errorMessage,
      loading,
      success,
      email,
      emailError,
      emailRequired,
      emailValid,
      name,
      nameError,
      nameRequired,
      nameValid,
      formSubmitedOnce,
    } = this.state;
    return (
      <Layout
        cover="/assets/images/covers/reservation.jpg"
        title="Paiement de votre commande"
      >
        <div className="payment-container">
          <div className="payment-main">
            {/* FORM */}
            <div className="payment-forms">
              <CardSelector />
              <div className="payment-forms--wrapper">
                <form className="payment-form" onSubmit={() => this.handleSubmit(true)}>
                  <div className="field">
                    <div className="input">
                      <TextInput
                        label="Adresse Mail: *"
                        name="email"
                        placeHolder="Adresse email"
                        value={email}
                        valid={emailValid}
                        required={emailRequired}
                        formSubmitedOnce={formSubmitedOnce}
                        errorMessage={emailError}
                        handleChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="input">
                      <TextInput
                        label="Nom: *"
                        name="name"
                        placeHolder="Nom"
                        value={name}
                        valid={nameValid}
                        required={nameRequired}
                        formSubmitedOnce={formSubmitedOnce}
                        errorMessage={nameError}
                        handleChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <StripeElementWrapper
                    label="Card number: *"
                    component={CardNumberElement}
                  />
                  <StripeElementWrapper
                    label="Expiration: *"
                    component={CardExpiryElement}
                  />
                  <StripeElementWrapper label="CVC: *" component={CardCVCElement} />
                </form>
                {loading && (
                  <div className="loading-container">
                    <LoadingBox
                      loading={loading}
                      success={success}
                      error={error}
                      showErrorButton
                      errorMessage={errorMessage}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Order */}
            <div className="order-help-container">
              <div className="order-help-order">
                <CurrentOrder beforeClickHandler={this.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
    orderService: bindActionCreators(orderService, dispatch),
  };
}

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectStripe(Credit)),
);
