/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import './style.scss';

const TestimonialCard = (props) => {
  const { device, testimonial } = props;
  const isDesktop = device === 'desktop';
  const { imageSrc, subTitle, description } = testimonial;
  return (
    <div className="testimonial-card-content">
      <div
        className="testimonial-card-image-container"
        style={{ backgroundImage: `url("${imageSrc}")` }}
      />
      {isDesktop ? (
        <div className="testimonial-card-info-container">
          <div className="testimonial-card-description">{description}</div>
          <div className="testimonial-card-subtitle">{subTitle}</div>
        </div>
      ) : (
        <>
          <div className="testimonial-card-subtitle">{subTitle}</div>
          <div className="testimonial-card-description">{description}</div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  device: state.device,
});

export default connect(mapStateToProps)(TestimonialCard);
