/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { MdFileUpload } from 'react-icons/md';

import './style.scss';

const UploadButton = (props) => {
  const {
    handleClick, fitToContainer, color, rounded,
  } = props;
  return (
    <div
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex="0"
      className={`upload-button-container ${fitToContainer ? 'fit' : null} ${color || null} ${rounded ? 'rounded' : null}`}
    >
      <span className="text-button-text"><MdFileUpload /></span>
    </div>
  );
};

UploadButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default UploadButton;
