/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import ReactGA from 'react-ga';

import ScrollToTop from 'containers/ScrollToTop';
import { bindActionCreators } from 'redux';
import { CircleSpinner } from 'react-spinners-kit';
import CookieConsent from 'react-cookie-consent';
import Paypal from 'containers/Payment/Paypal';
import DeviceDetection from './containers/DeviceDetection';
import Header from './containers/Header';
import Footer from './containers/Footer';
import Home from './containers/Home';
import Contact from './containers/Contact';
import Faq from './containers/Faq';
import Connection from './containers/Connection';
import Editions from './containers/Editions';
import Format from './containers/Format';
import Upload from './containers/Upload';
import OrderRecap from './containers/OrderRecap';
import ValidateAccount from './containers/ValidateAccount';
import PersonalPage from './containers/PersonalPage';
import ForgetPassword from './containers/ForgetPassword';
import DownloadMaterial from './containers/DownloadMaterial';
import SignUp from './containers/SignUp';
import Credit from './containers/Payment/Credit';
import Debit from './containers/Payment/Debit';
import * as userServices from './services/user';

import './App.scss';
import './App.module.scss';

ReactGA.initialize(process.env.REACT_APP_GA);
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {
  componentWillMount = async () => {
    const { user, userService } = this.props;
    if (!user.isReady) {
      await userService.initApp();
    }
  }

  render() {
    const { user } = this.props;
    if (!user.isReady) {
      return (
        <div className="page-loading">
          <CircleSpinner size={80} color="#2095a9" />
        </div>
      );
    }
    return (
      <ScrollToTop>
        <div className="App">
          <DeviceDetection />
          <div id="header">
            <Header />
          </div>
          <div id="content">
            <Switch>
              <Route exact path="/" render={() => <Home />} />
              <Route path="/editions" component={Editions} />
              <Route path="/format" component={Format} />
              <Route path="/contact" component={Contact} />
              <Route path="/connection" component={Connection} />
              <Route path="/upload" component={Upload} />
              <Route path="/faq" component={Faq} />
              <Route path="/orderRecap" component={OrderRecap} />
              <Route path="/personalPage" component={() => <PersonalPage currentPage="Mes Commandes" />} />
              <Route path="/personalInfo" component={() => <PersonalPage currentPage="Informations personnelles" />} />
              <Route path="/modifyPassword" component={() => <PersonalPage currentPage="Modifier mot de passe" />} />
              <Route path="/inscription" component={SignUp} />
              <Route path="/user/validate" component={ValidateAccount} />
              <Route path="/user/forgetPassword" component={ForgetPassword} />
              <Route path="/order/:order_id/material/download" component={DownloadMaterial} />
              <Route
                path="/payment/credit"
                component={() => (
                  <Elements locale="FR">
                    <Credit />
                  </Elements>
                )}
              />
              <Route
                path="/payment/debit"
                component={() => (
                  <Elements locale="FR">
                    <Debit />
                  </Elements>
                )}
              />
              <Route path="/payment/paypal" component={Paypal} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </div>
          <div id="footer">
            <Footer />
          </div>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="J'ai compris"
          cookieName="webshop-proximag"
          style={{ background: '#2B373B', fontSize: '13px' }}
          buttonStyle={{
            color: 'white', fontSize: '13px', textTransform: 'inherit', background: '#2095a9',
          }}
          expires={150}
        >
        Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur
        notre site et afin d’améliorer l’expérience de nos utilisateurs. Les cookies sont des
        données
        qui sont téléchargés ou stockés sur votre ordinateur ou sur tout autre appareil.
        </CookieConsent>
      </ScrollToTop>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userService: bindActionCreators(userServices, dispatch),
  };
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps,
  mapDispatchToProps)(App);
