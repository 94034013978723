import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import zxcvbn from 'zxcvbn';

class PasswordStrength extends Component {
  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Faible';
      case 1:
        return 'Faible';
      case 2:
        return 'Acceptable';
      case 3:
        return 'Fort';
      case 4:
        return 'Fort';
      default:
        return 'Faible';
    }
  }

  render() {
    const { form } = this.props;
    const password = form.modifyPassword && form.modifyPassword.values ? form.modifyPassword.values.newPassword : '';
    const testedResult = zxcvbn(password);
    return (
      <div className="password-strength-meter">
        <progress
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
          value={testedResult.score}
          max="4"
        />
        <br />
        <label
          className="password-strength-meter-label"
        >
          {testedResult && (
            <>
              <strong>Fiabilité du mot de passe :</strong>
              {' '}
              {this.createPasswordLabel(testedResult)}
            </>
          )}
        </label>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  form: state.form,
});


export default connect(
  mapStateToProps,
  null,
)(PasswordStrength);
