import fetch from 'isomorphic-fetch';

export const fetchWithCredentials = async (url, options) => {
  options = options || {};
  options.headers = options.headers || {};
  options.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  const response = await fetch(url, options);
  return response;

  // With refreshToken logic
  // if (response.ok) {
  //   return response;
  // }

  // if (response.status === 401 && response.headers.has('Token-Expired')) {
  //   var refreshResponse = await refreshToken(true);
  //   if (refreshResponse.error) {
  //     return response;
  //   }
  //   return await fetchWithCredentials(url, options);
  // } else {
  //   return response;
  // }
};
