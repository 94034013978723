/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  FaAngleRight,
  FaAngleDown,
  FaRegCheckSquare,
  FaRegSquare,
} from 'react-icons/fa';

import './style.scss';

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.clickHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.clickHandler);
  }

  handleHeaderClick() {
    const { handleHeaderClick } = this.props;
    handleHeaderClick();
  }

  // eslint-disable-next-line consistent-return
  clickHandler(e) {
    const { onClickOutside } = this.props;
    if (this.node.contains(e.target)) {
      return e.target;
    } if (onClickOutside) {
      onClickOutside();
    }
  }

  render() {
    const {
      partOfForm,
      overlay,
      errorMessage,
      valid,
      noHead,
      name,
      isOpen,
      showErrors,
      redBorderOnError,
      children = [],
      value,
      headerCheckBoxes,
    } = this.props;
    return (
      <div
        ref={node => (this.node = node)} // eslint-disable-line no-return-assign
        className={`dropdown-container ${overlay ? 'overlay' : ''}`}
      >
        <div className="dropdown-content">
          {noHead ? null : (
            <>
              <div
                className={`dropdown-header ${
                  partOfForm ? 'part-of-form' : 'standalone'
                } 
                ${errorMessage && redBorderOnError ? 'red' : ''}
                ${isOpen ? 'open' : 'closed'}`}
                onClick={() => this.handleHeaderClick()}
                onKeyPress={() => this.handleHeaderClick()}
                tabIndex="0"
                role="button"
              >
                <div
                  className={`header-title ${
                    partOfForm ? 'part-of-form' : null
                  } 
                  ${errorMessage && redBorderOnError ? 'red' : ''}
                ${value ? 'value-selected' : null}`}
                >
                  {name}
                </div>
                <div className="header-icon">
                  {headerCheckBoxes ? (
                    isOpen ? (
                      <FaRegCheckSquare />
                    ) : (
                      <FaRegSquare />
                    )
                  ) : isOpen ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </div>
              </div>
            </>
          )}
          <div className={`dropdown-children ${overlay ? 'overlay' : ''}`}>
            {isOpen ? children.map(child => child) : null}
          </div>
          {showErrors ? (
            <div className="dropdown-error-placeholder">
              {valid ? null : (
                <div className="text-input-error">{errorMessage}</div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default DropDown;
