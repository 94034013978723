/* eslint-disable class-methods-use-this */
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import TextButton from 'components/TextButton';
import RenderField from 'components/RenderField';
import PasswordStrength from 'components/PasswordStrength';
import * as userService from '../../services/user';
import { validatePassword } from '../../helpers/validate';
import './style.scss';
import LoadingBox from 'components/LoadingBox';


const ModifyPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { user, modifyPassword } = props;
    const {
      updatePassword,
    } = props.userService;
    setLoading(true);
    if (modifyPassword.values
      && (modifyPassword.values.confirmNewPassword === modifyPassword.values.newPassword)) {
      try {
        await updatePassword(modifyPassword.values.newPassword, user.id);
        setLoading(false);
        setErrorMessage('');
        setSuccessMessage('Mot-de-passe mis à jour.');
      } catch (err) {
        setLoading(false);
        setSuccessMessage('');
        setErrorMessage(err.message);
      }
    } else {
      setLoading(false);
      setSuccessMessage('');
      setErrorMessage('Vérifiez vos mot-de-passes.');
    }
  };

  const hideLoader = () => {
    setLoading(false);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const goBack = () => {
    const { history } = props;
    history.push('/personalPage');
  };

  return (
    <>
      {successMessage !== '' && (
        <span className="editpwd-container--message">
          {successMessage}
        </span>
      )}
      {errorMessage !== '' && (
        <span className="editpwd-container--message editpwd-container--message--error">
          {errorMessage}
        </span>
      )}
      {loading ? (
        <div className="loading-container">
          <LoadingBox
            loading={loading}
            success={successMessage !== ''}
            error={errorMessage !== ''}
            showErrorButton
            handleClick={hideLoader}
            errorMessage={errorMessage}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <Field
            name="newPassword"
            type="password"
            component={RenderField}
            placeholder="Nouveau mot de passe"
            label="Nouveau mot de passe"
          />
          <Field
            name="confirmNewPassword"
            type="password"
            component={RenderField}
            placeholder="Confirmer nouveau mot de passe"
            label="Confirmer nouveau mot de passe"
          />
          <PasswordStrength />
          <div className="modifyPassword-buttons--row">
            <TextButton
              text="Annuler"
              handleClick={goBack}
              color="pink"
            />
            <TextButton
              handleClick={handleSubmit}
              text="Modifier mot de passe"
              type="submit"
            />
          </div>
        </form>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  modifyPassword: state.form.modifyPassword,
});

const mapDispatchToProps = dispatch => ({
  userService: bindActionCreators(userService, dispatch),
});

const modifyPassword = connect(mapStateToProps, mapDispatchToProps)(ModifyPassword);

export default withRouter(reduxForm({ form: 'modifyPassword', validatePassword })(modifyPassword));
