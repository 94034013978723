/* eslint-disable react/prop-types */
import React from 'react';
import {
  FaAngleRight,
  FaAngleDown,
} from 'react-icons/fa';
import s from './style.module.scss';

const DropdownEditions = (props) => {
  const display = props.isOpen ? 'block' : 'none';
  const background = '#2095a9';
  const { name, children } = props;

  return (
    <div className={s.container}>
      <div
        onClick={() => props.onClick()}
        onKeyPress={() => props.onClick()}
        role="button"
        tabIndex="0"
        className={s.container__item}
        style={{ background }}
      >
        {name}
        {display === 'none' ? <FaAngleRight /> : <FaAngleDown />}
      </div>
      <div style={{ display }}>{children}</div>
    </div>
  );
};

export default DropdownEditions;
