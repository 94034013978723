/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BasketIndicator from '../../components/BasketIndicator';
import NavBar from '../NavBar';

import './headerDesktop.scss';


class HeaderDesktop extends React.Component {
  handleClick(destination, nextPage) {
    const { location, history } = this.props;
    if (location.pathname !== destination) {
      history.push(destination, { nextPage });
    }
  }

  render() {
    return (
      <>
        <div className="header-desktop-container">
          <div
            className="header-desktop-logo-container"
            onClick={() => this.handleClick('/')}
            onKeyPress={() => this.handleClick('/')}
            role="button"
            tabIndex="0"
          >
            <img src="/assets/images/logo.svg" alt="logo" />
          </div>
          <NavBar />
          <div className="header-desktop-search-and-basket-container">
            <BasketIndicator />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(HeaderDesktop));
