/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import s from './style.module.scss';

const modalDesktop = {
  maxHeight: 'inherit',
  overflowY: 'scroll',
  width: 'calc(100% / 2)',
  marginTop: '10vh',
  marginBottom: '10vh',
};

const modalTablet = {
  maxHeight: 'inherit',
  overflowY: 'scroll',
  width: '90%',
  marginTop: '10vh',
  marginBottom: '10vh',
};

const Cookies = (props) => {
  const { display, onClickOutside, device } = props;
  const isTablet = device === 'tablet';
  const isMobile = device === 'mobile';
  return (
    display && (
    <Modal
      style={isTablet || isMobile ? modalTablet : modalDesktop}
      onClickOutside={onClickOutside}
      closeButton
    >
      <div className={s.container}>
        <ul className={s.container__list}>
          <li>
            <h5>Qu’est-ce qu’un cookie&nbsp;?</h5>
            <p>Un “cookie” est un petit fichier créé par certains sites internet et qui est stocké dans un ordinateur afin de faciliter ultérieurement la navigation Internet depuis cet ordinateur. Ce fichier permet aux sites d’y retrouver aisément certaines informations relatives aux utilisateurs afin de leur procurer lors de chaque visite une navigation personnalisée (par exemple en ce qui concernant la taille de l’écran, la version du navigateur ou un choix de langue).</p>
          </li>
          <li>
            <h5>Pourquoi notre site utilise-t-il des cookies&nbsp;?</h5>
            <p>Le cookies et les informations qu’ils contiennent nous permettent de&nbsp;:</p>
            <ul>
              <li>Faciliter votre navigation sur notre site</li>
              <li>Permettre son bon fonctionnement</li>
              <li>Personnaliser les contenus (rédactionnels et publicitaires) qui vous sont présentés</li>
              <li>Vous offrir des fonctionnalités relatives aux réseaux sociaux (partages, commentaires,…)</li>
              <li>Mesurer et analyser l’audience de notre site</li>
              <li>Analyser le comportement de nos utilisateurs sur notre site</li>
            </ul>
          </li>
          <li>
            <h5>Par qui ces cookies sont-ils utilisés&nbsp;?</h5>
            <p>
                L’émetteur d’un cookie est seul susceptible de lire ou de modifier les informations qui y sont contenues.
              <br />
                Certains cookies sont installés directement par notre site afin de faciliter votre navigation, retenir certaines de vos préférences techniques ou encore optimiser l’affichage de certains contenus, notamment publicitaires, en fonction des caractéristiques de votre terminal.
              <br />
                D’autres cookies sont émis par des tiers et sont de ce fait soumis aux politiques de protection de la vie privée respectives de chacun de ces tiers. Il s’agit notamment de cookies installés du fait de la présence d’applications tierces sur notre site ou de la diffusion de contenus tiers dans nos espaces publicitaires.
            </p>
          </li>
          <li>
            <h5>Que faire si je ne veux pas de ces cookies&nbsp;?</h5>
            <p>Vous avez la possibilité à tout moment de vous opposer à l’utilisation de cookies.</p>
            <p>Désactiver les cookies émis par les réseaux sociaux</p>
            <ul>
              <li>FACEBOOK&nbsp;: https://www.facebook.com/help/360595310676682/</li>
              <li>TWITTER&nbsp;: https://support.twitter.com/articles/20171379#</li>
              <li>YOUTUBE&nbsp;: https://support.google.com/accounts/answer/61416?hl=fr</li>
              <li>LINKEDIN&nbsp;: https://www.linkedin.com/legal/cookie-policy</li>
              <li>GOOGLE+&nbsp;: https://support.google.com/accounts/answer/61416?hl=fr</li>
            </ul>
          </li>
          <li>
            <h5>Désactiver les cookies émis par un de nos partenaires publicitaires</h5>
            <p>Vous avez la possibilité de vous opposer à la publicité ciblée en fonction de vos centres d’intérêts ou de votre comportement. Notez que vous continuerez toutefois à recevoir les publicités non-ciblées.</p>
            <ul>
              <li>Cookies DoubleClick for Publishers&nbsp;: https://adssettings.google.com/u/0/authenticated</li>
              <li>Cookies Appnexus&nbsp;: https://www.appnexus.com/en/company/platform-privacy-policy-fr</li>
              <li>Cookies Ligatus&nbsp;: https://www.ligatus.com/fr/privacy-policy</li>
              <li>Cookies Rubicon Projet&nbsp;: https://rubiconproject.com/privacy/consumer-online-profile-and-opt-out/</li>
              <li>Cookies Outbrain&nbsp;: https://www.outbrain.com/legal/#advertising_behavioral_targeting</li>
              <li>Cookies Sharethrough&nbsp;: https://platform-cdn.sharethrough.com/privacy-policy</li>
            </ul>
          </li>
          <li>
            <h5>Paramétrer votre navigateur</h5>
            <p>Nous vous recommandons de naviguer sur Internet avec un navigateur récent qui vous offrira davantage de garantie de sécurité et vous permettra de paramétrer la gestion des cookies selon vos préférences&nbsp;:</p>
            <ul>
              <li>Google Chrome (versions 21 et ultérieures): page gestion des cookies</li>
              <li>Mozilla Firefox (versions 14 et ultérieures): instructions pour gestion des cookies</li>
              <li>Internet Explorer (Windows uniquement, versions 9 et ultérieures): page gestion des cookies</li>
              <li>Apple Safari (ordinateurs Apple uniquement, versions 6 et ultérieures): instructions pour Safari</li>
            </ul>
          </li>
        </ul>
      </div>
    </Modal>
    ));
};

Cookies.defaultProps = {
  display: false,
};


const mapStateToProps = state => ({
  device: state.device,
});

export default connect(mapStateToProps)(Cookies);
