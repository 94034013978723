import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingBox from 'components/LoadingBox';
import TextInput from 'components/TextInput';
import { urlParam } from 'helpers/url';
import CurrentOrder from 'containers/CurrentOrder';
import * as currentOrderActions from '../../actions/currentOrderActions';
import CardSelector from './CardSelector';
import Layout from '../../components/Layout';
import './style.scss';
import * as orderService from '../../services/order';

class Debit extends React.Component {
  constructor(props) {
    super(props);
    const client_secret = urlParam('client_secret');
    const next_source = urlParam('source');
    const livemode = urlParam('livemode');
    this.state = {
      client_secret,
      next_source,
      livemode,
      error: false,
      errorMessage: '',
      showForm: false,
      loading: true,
      success: false,
      name: props.user.isConnected
        ? `${props.user.firstName} ${props.user.lastName}`
        : '',
      nameError: '',
      nameRequired: true,
      nameValid: true,
      email: props.user.mail,
      emailError: '',
      emailRequired: true,
      emailValid: true,
      formSubmitedOnce: false,
    };
  }

  componentWillMount() {
    const { user, history, currentOrder } = this.props;
    if (!user.isConnected) {
      history.push('/connection', { nextPage: '/payment/credit' });
    }
    if (!currentOrder.savedOrder) {
      history.push('/personalPage');
    }
  }


  async componentDidMount() {
    const {
      currentOrderActions, orderService, stripe, history, currentOrder,
    } = this.props;
    currentOrderActions.clearCurrentOrderError();
    const { client_secret, next_source } = this.state;
    // Get url params
    if (!next_source && !client_secret) {
      this.setState({ loading: false, showForm: true });
      return;
    }
    // Check if source is chargeable
    const { source, error } = await stripe.retrieveSource({
      id: next_source,
      client_secret,
    });
    if (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        loading: false,
      });
      return;
    }
    if (source.status === 'chargeable') {
      // Confirm payment
      try {
        await orderService.payOrder({
          id: currentOrder.savedOrder.paymentIntentId,
          paymentMethodId: 'bancontact',
          orderId: currentOrder.savedOrder.id,
          source: next_source,
          description: `COMMANDE ${currentOrder.savedOrder.orderNumber}`,
        });
        history.push('/personalPage?message=payment_success');
      } catch (err) {
        this.setState({
          error: true,
          errorMessage: err.message,
          loading: false,
        });
      }
    } else if (source.status === 'failed') {
      this.setState({
        error: true,
        errorMessage: 'Votre transaction a échoué',
        loading: false,
      });
    } else {
      this.setState({
        error: true,
        errorMessage: "Votre transaction n'est pas chargeable",
        loading: false,
      });
    }
  }

  handleSubmit = async () => {
    const { stripe, currentOrder } = this.props;
    if (!this.validateFields()) throw new Error('Erreur dans vos informations');
    this.setState({ loading: true });
    const { savedOrder } = currentOrder;
    const amount = (savedOrder.totalPrice * 100).toFixed(2);
    const { source, error } = await stripe.createSource({
      type: 'bancontact',
      currency: 'eur',
      amount: Number(amount),
      owner: {
        name: this.state.name,
        email: this.state.email,
      },
      statement_descriptor: `Commande: ${savedOrder.orderNumber}`,
      redirect: {
        return_url: window.location.href,
      },
    });
    if (error) {
      this.setState({ loading: false });
      throw error;
    } else {
      window.location.href = source.redirect.url;
      return 'no_redirect';
    }
  };


  validateFields = () => {
    const {
      name, nameRequired, email, emailRequired,
    } = this.state;

    const nameValid = nameRequired ? !!name : true;
    const emailValid = emailRequired
      ? !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      : true;

    const nameError = nameValid ? '' : 'ce champ est requis';
    const emailError = email
      ? emailValid
        ? ''
        : 'email non valide'
      : 'ce champ est requis';

    // Toggle form submited once
    const formSubmitedOnce = true;

    // Check form validity
    const formValid = ![nameValid, emailValid].includes(false);
    this.setState({
      nameValid,
      nameError,
      emailValid,
      emailError,
      formSubmitedOnce,
    });

    return formValid;
  };

  handleChange = ({ name, value, required }) => {
    const { formSubmitedOnce } = this.state;
    const nameValid = `${name}Valid`;
    const nameErrorMessage = `${name}Error`;
    let valid = true;
    let errorMessage;

    if (required && !value && formSubmitedOnce) {
      valid = false;
      errorMessage = 'ce champs et requis';
    }

    this.setState({ [name]: value });
    this.setState({ [nameValid]: valid });
    this.setState({ [nameErrorMessage]: valid ? '' : errorMessage });
  };

  render() {
    const {
      error,
      errorMessage,
      showForm,
      loading,
      success,
      email,
      emailError,
      emailRequired,
      emailValid,
      name,
      nameError,
      nameRequired,
      nameValid,
      formSubmitedOnce,
    } = this.state;
    return (
      <Layout
        cover="/assets/images/covers/reservation.jpg"
        title="Paiement de votre commande"
      >
        <div className="payment-container">
          <div className="payment-main">
            {/* FORM */}
            <div className="payment-forms">
              <CardSelector />
              <div className="payment-forms--wrapper">
                {showForm ? (
                  <form className="payment-form">
                    <div className="field">
                      <div className="input">
                        <TextInput
                          label="Adresse Mail: *"
                          name="email"
                          placeHolder="Adresse email"
                          value={email}
                          valid={emailValid}
                          required={emailRequired}
                          formSubmitedOnce={formSubmitedOnce}
                          errorMessage={emailError}
                          handleChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="input">
                        <TextInput
                          label="Nom: *"
                          name="name"
                          placeHolder="Nom"
                          value={name}
                          valid={nameValid}
                          required={nameRequired}
                          formSubmitedOnce={formSubmitedOnce}
                          errorMessage={nameError}
                          handleChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="loading-container">
                    <LoadingBox
                      loading={loading}
                      success={success}
                      error={error}
                      showErrorButton
                      errorMessage={errorMessage}
                      handleClick={() => {
                        // Remove query string from url to prevent error on second submission
                        window.history.replaceState(null, null, window.location.pathname);
                        this.setState({ loading: false, showForm: true });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Order */}
            <div className="order-help-container">
              <div className="order-help-order">
                <CurrentOrder beforeClickHandler={this.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
    orderService: bindActionCreators(orderService, dispatch),
  };
}

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectStripe(Debit)),
);
