/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key, class-methods-use-this, consistent-return */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import axios from 'axios';
import 'moment/locale/fr';
import * as currentOrderActions from '../../actions/currentOrderActions';

import CurrentOrder from '../CurrentOrder';
import DropDown from '../../components/DropDown';
import TextInputSearch from '../../components/TextInputSearch';
import DropdownEditions from '../../components/DropdownEditions';
import CheckedInput from '../../components/CheckedInput';
import Layout from '../../components/Layout';

import { endpoints } from '../../api';
import postalCodes from '../../commons/postalCodes.json';
import communes from '../../commons/communes.json';
import map from '../../resources/data/carte.pdf';

import './style.scss';

const FORMAT_MIN_PRICE = '1/12 V';

class Editions extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/destructuring-assignment
    const { orderData } = this.props.currentOrder;
    this.state = {
      api: [],
      editionId: orderData.editionId,
      publicationDateIds: orderData.publicationDateIds,
      searchText: '',
      searchPlaceHolder: 'rechercher...',
      searchDropDownOpen: false,
      searchPropositions: [],
      // eslint-disable-next-line react/destructuring-assignment, valid-typeof
      selectedRegion: typeof this.props.currentOrder.region !== undefined ? this.props.currentOrder.region : null,
    };
  }

  componentDidMount() {
    const { getEditions } = endpoints;
    const { currentOrderActions } = this.props;
    currentOrderActions.setSavedOrder(false);
    axios.get(getEditions)
      .then((res) => {
        this.setState({
          api: res.data,
        });
      });
  }

  getEditionId(edition, fromSearch = false) {
    const { editionId } = this.state;
    const {
      id, name, editionFormatPrices, printCount,
    } = edition;
    const {
      setEdition, setOrderDataEdition, setSelectedEditionDates, setOrderDataEditionDates, setFromPrice,
    } = this.props.currentOrderActions;
    if (editionId === id && !fromSearch) {
      this.setState({
        editionId: '',
        searchDropDownOpen: false,
        publicationDateIds: [],
      });
      setEdition(undefined);
      setOrderDataEdition(undefined);
      setFromPrice(0);
      setSelectedEditionDates([]);
      setOrderDataEditionDates([]);
      return;
    }

    const { price } = editionFormatPrices.find(formats => formats.format.value === FORMAT_MIN_PRICE);
    this.setState({
      editionId: id,
      searchDropDownOpen: false,
      publicationDateIds: [],
    });
    const displayName = (
      <div>
        {name}
        <br />
        Tirages:
        {' '}
        {printCount}
        ex
      </div>
    );
    setEdition(displayName);
    setOrderDataEdition(id);
    setFromPrice(price);
    setSelectedEditionDates([]);
    setOrderDataEditionDates([]);
  }

  getDatesId(id, date) {
    const { currentOrderActions, currentOrder } = this.props; // eslint-disable-line no-shadow
    const { selectedEditionDates } = currentOrder;
    const { publicationDateIds } = this.state;
    const indexId = publicationDateIds.indexOf(id);
    const publicationDateIdsArray = [...publicationDateIds];
    const publicationDateArray = [...selectedEditionDates];

    if (publicationDateIds.includes(id)) {
      publicationDateIdsArray.splice(indexId, 1);
      publicationDateArray.splice(indexId, 1);
      this.setState({ publicationDateIds: publicationDateIdsArray.sort((a, b) => a - b) });
      currentOrderActions.setSelectedEditionDates(publicationDateArray);
      currentOrderActions.setOrderDataEditionDates(publicationDateArray);
    } else {
      this.setState({ publicationDateIds: [...publicationDateIds, id] });
      currentOrderActions.setSelectedEditionDates([...selectedEditionDates, date].sort((a, b) => a - b));
      currentOrderActions.setOrderDataEditionDates([...publicationDateIds, id]);
    }
    currentOrderActions.clearCurrentOrderError();
  }

  getSearchEdition(value, city) {
    const edition = this.getEditionFromPostalCode(value);
    this.setState({
      searchText: `${city} - ${value} `,
    });
    const newIndex = this.getMunicipalityByPostalCode(edition, city, value);
    this.handleDropDownHeaderClick(newIndex, true);
    this.getEditionId(edition, true);
  }

  getEditionFromPostalCode(postCode) {
    const { api } = this.state;
    return api.find(edition => edition.municipalities.find(place => postCode === place.postalCode && edition !== undefined));
  }

  getMunicipalityByPostalCode(edition, city, postCode) {
    let ind;
    edition.municipalities.map((place) => {
      const { provinces } = edition;
      if (place.name.toUpperCase() === city.toUpperCase() || place.postalCode === postCode) {
        const isValidDistrict = provinces.map(p => p.name).indexOf(place.province.name);
        let index;
        if (isValidDistrict === -1) {
          index = postalCodes.map(f => f.name).indexOf(edition.provinces[0].name);
        } else {
          index = postalCodes.map(f => f.name).indexOf(edition.provinces[isValidDistrict].name);
        }
        if (index !== -1) {
          ind = index;
        }
      }
    });
    return ind;
  }

  isEditionInDistrict(district, edition) {
    let isInDistrict = false;
    edition.provinces.map((province) => {
      if (province.name === district) {
        isInDistrict = true;
      }
    });
    return isInDistrict;
  }

  handleSearch({ value }) {
    this.setState({ searchText: value });
    if (!value) {
      this.setState({ searchPropositions: [], searchDropDownOpen: false });
      return;
    }
    const regex = new RegExp(`^${value}`, 'i');
    const searchPropositions = communes
      .filter(commune => regex.test(commune.name) || regex.test(commune.postalCode))
      .filter((commune) => {
        if (this.getEditionFromPostalCode(commune.postalCode) !== undefined) {
          return commune;
        }
      })
      .slice(0, 15);
    this.setState({ searchPropositions, searchDropDownOpen: true });
  }

  handleDropDownHeaderClick(index, fromSearch = false) {
    const { selectedRegion } = this.state;
    const newIndex = selectedRegion !== index || fromSearch ? index : undefined;
    this.setState({ selectedRegion: newIndex });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.currentOrderActions.setRegion(index);
  }

  render() {
    const {
      editionId,
      publicationDateIds,
      searchText,
      selectedRegion,
      searchPlaceHolder,
      searchDropDownOpen,
      searchPropositions,
      api,
    } = this.state;
    const getMunicipalities = postalCodes.map((place, index) => (
      <DropdownEditions onClick={() => this.handleDropDownHeaderClick(index)} name={place.name} key={index} isOpen={selectedRegion === index}>
        {api.map((edition) => {
          if (this.isEditionInDistrict(place.name, edition)) {
            return (
              <CheckedInput
                key={edition.id}
                name={edition.name}
                onClick={() => this.getEditionId(edition)}
                onKeyPress={() => this.getEditionId(edition.id)}
                role="presentation"
                checked={edition.id === editionId}
              />
            );
          }
        })}
      </DropdownEditions>
    ));

    // We still need to remove the last item because in 'postalCodes' because it contains Hannut twice
    // But we will keep the logic there '../../commons/postalCodes.json'
    // if we need one day the postalCode min and max
    getMunicipalities.splice(getMunicipalities.length - 1, 1);

    const getEditionDates = editionId !== ''
      ? api.map(edition => (
        edition.id === editionId
          ? edition.publicationDates.map(date => (
            <CheckedInput
              key={date.id}
              name={`Proximag du ${moment.unix(date.dateTimeStamp).format('dddd D MMMM YYYY')}`}
              onClick={() => this.getDatesId(date.id, date.dateTimeStamp)}
              onKeyPress={() => this.getDatesId(date.id, date.dateTimeStamp)}
              role="presentation"
              checked={publicationDateIds.includes(date.id)}
            />
          ))
          : null
      ))
      : <p>Choisissez une edition ou effectuez une recherche.</p>;
    return (
      <Layout
        cover="/assets/images/covers/reservation.jpg"
        title="Réservation"
      >
        <div className="editions-container">
          {/* SEARCH */}
          <div className="editions-search">
            <div className="editions-search-content">
              <div className="search-title">
                <div className="search-title-content">
                  <a className="text-button-container" href={map} target="blank" rel="noopener noreferrer">Carte des éditions</a>
        Choisissez l&apos;édition que vous souhaitez couvrir selon la
        commune ou le code postal choisi:
                </div>
              </div>
              <div className="search-input">
                <div className="search-input-content">
                  <div>
                    <div className="input">
                      <TextInputSearch
                        name="searchInput"
                        value={searchText}
                        placeHolder={searchPlaceHolder}
                        noErrorMessages
                        clickHandler={() => this.setState({ searchDropDownOpen: true })
                        }
                    // eslint-disable-next-line react/jsx-no-bind
                        handleChange={this.handleSearch.bind(this)}
                      />
                    </div>
                    <div className="dropdown">
                      <DropDown
                        noHead
                        onClickOutside={() => this.setState({ searchDropDownOpen: false })}
                        isOpen={searchDropDownOpen}
                        overlay
                      >
                        {searchPropositions.map((option, index) => (
                          <div
                            className="editions-search-dropdown-option"
                            key={index} // eslint-disable-line react/no-array-index-key
                            onClick={() => this.getSearchEdition(option.postalCode, option.name)}
                            onKeyPress={() => this.getSearchEdition(option.postalCode, option.name)}
                            role="button"
                            tabIndex="0"
                          >
                            {`${option.name} - ${option.postalCode}`}
                          </div>
                        ))}
                      </DropDown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="editions-accordeon-dates-pricing">
            <div className="editions-accordeon-dates-pricing-content">
              <div className="accordeon-dates">
                <div className="accordeon-dates-content">
                  {/* <ACCORDEON, EDITION SELECTION> */}
                  <div className="accordeon">
                    <div className="accordeon-content">
                      {getMunicipalities}
                    </div>
                  </div>
                  <div className="dates">
                    <div className="dates-content">
                      <div className="dates-header" style={{ display: editionId ? 'block' : 'none' }}>
                      Choisissez les dates souhaitées pour votre parution
                      </div>
                      {getEditionDates}
                    </div>
                  </div>
                </div>
              </div>
              {/* <PRICING> */}
              <div className="order-help">
                <div className="order-help-content">
                  <div className="order-help-order">
                    <CurrentOrder from />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="editions-mention">Pour un plan média plus large, contactez-nous via webshop@lavenir.net</p>
        </div>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
});

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Editions);
