/* eslint-disable react/prop-types */
import React from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';

import TextButton from '../TextButton';

import './style.scss';

class ErrorBox extends React.Component {
  handleClick() {
    const { handleClick } = this.props;
    if (handleClick) handleClick();
  }

  render() {
    const { errorMessage, showIcon, showButton } = this.props;

    return (
      <div className="error-box">
        {showIcon ? (
          <div className="icon">
            <FaRegTimesCircle />
          </div>
        ) : null}
        <div className="error">{errorMessage}</div>
        {showButton && !showIcon ? (
          <div className="button">
            <TextButton text="OK" handleClick={() => this.handleClick()} />
          </div>
        ) : null}
        {showButton && showIcon ? (
          <div className="button">
            <TextButton text="OK" handleClick={() => this.handleClick()} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ErrorBox;
