import * as types from './actionTypes';

export const isReady = () => ({
  type: types.SET_USER_READY,
  payload: {},
});

export const loginUserSuccess = () => ({
  type: types.LOGIN_USER_SUCCESS,
  payload: {},
});

export const setEmail = mail => ({
  type: types.SET_USER_EMAIL,
  payload: {
    mail,
  },
});

export const getMeSuccess = user => ({
  type: types.GET_ME_SUCCESS,
  payload: {
    user,
  },
});

export const logoutUserSuccess = () => ({
  type: types.LOGOUT_USER_SUCCESS,
  payload: {},
});

export const updateMeSuccess = user => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: {
    user,
  },
});

export const signUpUser = () => ({
  type: types.SIGNUP_USER,
  payload: {},
});


// eslint-disable-next-line no-unused-vars
export const signUpUserSuccess = user => ({
  type: types.SIGNUP_USER_SUCCESS,
  payload: { user },
});


export const signUpUserFailure = () => ({
  type: types.SIGNUP_USER_FAILURE,
  payload: {},
});


export const signUpUserError = () => ({
  type: types.SIGNUP_USER_ERROR,
  payload: {},
});
