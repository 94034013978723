import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingBox from 'components/LoadingBox';
import CurrentOrder from 'containers/CurrentOrder';
import Layout from 'components/Layout';
import scriptLoader from 'react-async-script-loader';
import CardSelector from './CardSelector';
import * as orderService from '../../services/order';
import './style.scss';

const paypalKey = process.env.REACT_APP_PAYPAL_KEY;

const Paypal = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paypal, setPaypal] = useState(null);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const {
      isScriptLoaded, isScriptLoadSucceed, user, order, history,
    } = props;
    if (!user.isConnected) {
      history.push('/connection');
    }
    if (order.savedOrder === undefined || !order.savedOrder) {
      history.push('/personalPage');
    }
    if (isScriptLoaded && isScriptLoadSucceed) {
      setLoading(false);
      setShowButton(true);
      setPaypal(window.paypal);
    }
  }, [props]);

  const paypalPayment = (data, actions) => props.orderService
    .createPaypalOrder(props.order.savedOrder.id)
    .then((order) => {
      setLoading(true);
      return order.id;
    })
    .catch((err) => {
      setLoading(false);
      setError(true);
      setErrorMessage(err.message);
    });

  const onAuthorizePaypal = (data, actions) => props.orderService
    .confirmPaypalOrder(data.orderID, props.order.savedOrder.id)
    .then((captureResp) => {
      props.history.push('/personalPage?message=payment_success');
    })
    .catch((err) => {
      setLoading(false);
      setError(true);
      setErrorMessage(err.message);
    });

  const onCancelPaypal = () => {
    setLoading(false);
  };


  const PayPalButton = paypal !== null && paypal !== undefined
    ? paypal.Buttons.driver('react', { React, ReactDOM })
    : null;

  return (
    <Layout
      cover="/assets/images/covers/reservation.jpg"
      title="Paiement de votre commande"
    >
      <div className="payment-container">
        <div className="payment-main">
          {/* FORM */}
          <div className="payment-forms">
            <CardSelector />
            <div className="payment-forms--wrapper">
              {loading && (
                <div className="loading-container">
                  <LoadingBox
                    loading={loading}
                    success={false}
                    error={error}
                    showErrorButton
                    errorMessage={errorMessage}
                    handleClick={() => setLoading(false)}
                  />
                </div>
              )}
              <div className="payment-form">
                {showButton && (
                  PayPalButton !== null && (
                  <PayPalButton
                    createOrder={paypalPayment}
                    onApprove={onAuthorizePaypal}
                    onCancel={onCancelPaypal}
                    style={{
                      size: 'responsive',
                      color: 'white',
                      shape: 'rect',
                    }}
                  />
                  )
                )}
              </div>
            </div>
          </div>

          {/* Order */}
          <div className="order-help-container">
            <div className="order-help-order">
              <CurrentOrder />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    orderService: bindActionCreators(orderService, dispatch),
  };
}

const mapStateToProps = state => ({
  order: state.currentOrder,
  user: state.user,
});

export default withRouter(scriptLoader(
  `https://www.paypal.com/sdk/js?currency=EUR&disable-card=visa,mastercard,amex&client-id=${
    paypalKey}`,
)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Paypal)));
