// eslint-disable-next-line import/prefer-default-export
export const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Ce champ est obligatoire';
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Ce prénom est trop long...';
  }
  if (!values.lastName) {
    errors.lastName = 'Ce champ est obligatoire';
  } else if (values.lastName.length > 15) {
    errors.lastName = 'Ce nom est trop long...';
  }
  if (!values.mail) {
    errors.mail = 'Ce champ est obligatoire';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)) {
    errors.mail = 'Adresse e-mail invalide';
  }
  if (!values.code) {
    errors.code = 'Ce champ est obligatoire';
  }
  if (!values.password) {
    errors.password = 'Ce champ est obligatoire';
  }
  if (!values.municipality) {
    errors.municipality = 'Ce champ est obligatoire';
  }
  if (!values.companyType) {
    errors.companyType = 'Ce champ est obligatoire';
  }
  if (!values.companyName) {
    errors.companyName = 'Ce champ est obligatoire';
  }
  if (values.companyVAT && !values.companyVAT.trim().match(/^(BE0)([0-9]{9})$/i)) {
    errors.companyVAT = 'Numéro de TVA invalide';
  }
  if (!values.phone) {
    errors.phone = 'Ce champ est obligatoire';
  } else if (values.phone.length < 9 || values.phone.length > 10) {
    errors.phone = 'Numéro invalide';
  }
  if (!values.address) {
    errors.address = {
      street: 'Ce champ est obligatoire',
      number: 'Ce champ est obligatoire',
      postalCode: 'Ce champ est obligatoire',
      municipalityId: 'Ce champ est obligatoire',
    };
  } else {
    const addressErrors = {};
    if (!values.address.street) {
      addressErrors.street = 'Ce champ est obligatoire';
    }
    if (!values.address.number) {
      addressErrors.number = 'Ce champ est obligatoire';
    }
    if (!values.address.postalCode) {
      addressErrors.postalCode = 'Ce champ est obligatoire';
    } else if (
      values.address.postalCode.length < 4
      || values.address.postalCode.length > 4
    ) {
      addressErrors.postalCode = 'Code postal invalide';
    }
    if (!values.address.municipalityId) {
      addressErrors.municipalityId = 'Ce champ est obligatoire';
    }
    errors.address = addressErrors;
  }
  if (!values.object) {
    errors.object = 'Ce champ est obligatoire';
  }
  return errors;
};

export const validatePassword = (values) => {
  const errors = {};
  if (!values.object) {
    errors.object = 'Ce champ est obligatoire';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Ce champ est obligatoire';
  }
  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = 'Ce champ est obligatoire';
  }
  return errors;
};
