
const stripeErrors = {
  approve_with_id: 'le paiement ne peut pas être autorisé.',
  call_issuer: 'La carte a été refusée pour une raison inconnue.',
  card_not_supported: "La carte ne supporte pas ce type d'achat.",
  card_velocity_exceeded: 'Le client a dépassé le solde ou la limite de crédit disponible sur sa carte.',
  currency_not_supported: 'La carte ne prend pas en charge la devise spécifiée.',
  do_not_honor: 'La carte a été refusée pour une raison inconnue.',
  do_not_try_again: 'La carte a été refusée pour une raison inconnue.',
  duplicate_transaction: 'Une transaction avec un montant et des informations de carte de crédit identiques a été soumise très récemment.',
  expired_card: 'La carte a expiré.',
  fraudulent: 'Le paiement a été refusé car Stripe le soupçonne d’être frauduleux.',
  generic_decline: 'La carte a été refusée pour une raison inconnue.',
  incorrect_number: 'Le numéro de carte est incorrect.',
  incorrect_cvc: 'Le numéro CVC est incorrect.',
  incorrect_pin: "Le code PIN entré est incorrect. Ce code de refus ne s'applique qu'aux paiements effectués avec un lecteur de carte.",
  incorrect_zip: 'Le code postal est incorrect.',
  insufficient_funds: "La carte ne dispose pas de fonds suffisants pour finaliser l'achat.",
  invalid_account: 'La carte ou le compte auquel la carte est connectée est invalide.',
  invalid_amount: 'Le montant du paiement est invalide ou dépasse le montant autorisé.',
  invalid_cvc: 'Le numéro CVC est incorrect.',
  invalid_expiry_year: "L'année d'expiration est invalide.",
  invalid_number: 'Le numéro de carte est incorrect.',
  invalid_pin: "Le code PIN saisi est incorrect. Ce code de refus ne s'applique qu'aux paiements effectués avec un lecteur de carte.",
  issuer_not_available: "L'émetteur de la carte n'a pas pu être joint et le paiement n'a donc pas été autorisé.",
  lost_card: 'Le paiement a été refusé car la carte est perdue.',
  merchant_blacklist: "Le paiement a été refusé car il correspond à une valeur de la liste de blocage de l'utilisateur de Stripe.",
  new_account_information_available: 'La carte ou le compte auquel la carte est connectée est invalide.',
  no_action_taken: 'La carte a été refusée pour une raison inconnue.',
  not_permitted: "Le paiement n'est pas autorisé.",
  pickup_card: "La carte ne peut pas être utilisée pour effectuer ce paiement (il est possible qu'elle ait été perdue ou volée).",
  pin_try_exceeded: 'Le nombre autorisé de tentatives de code PIN a été dépassé.',
  processing_error: "Une erreur s'est produite lors du traitement de la carte.",
  reenter_transaction: "Le paiement n'a pas pu être traité par l'émetteur pour une raison inconnue.",
  restricted_card: "La carte ne peut pas être utilisée pour effectuer ce paiement (il est possible qu'elle ait été perdue ou volée).",
  revocation_of_all_authorizations: 'La carte a été refusée pour une raison inconnue.',
  revocation_of_authorization: 'La carte a été refusée pour une raison inconnue.',
  security_violation: 'La carte a été refusée pour une raison inconnue.',
  service_not_allowed: 'La carte a été refusée pour une raison inconnue.',
  stolen_card: 'Le paiement a été refusé car la carte a été volée.',
  stop_payment_order: 'La carte a été refusée pour une raison inconnue.',
  testmode_decline: 'Un numéro de carte de test de bande a été utilisé.',
  transaction_not_allowed: 'La carte a été refusée pour une raison inconnue.',
  try_again_later: 'La carte a été refusée pour une raison inconnue.',
  retire_count_limit_exceeded: 'Le client a dépassé le solde ou la limite de crédit disponible sur sa carte.',
};


export default stripeErrors;
