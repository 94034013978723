/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import s from './style.module.scss';

const modalDesktop = {
  maxHeight: 'inherit',
  overflowY: 'scroll',
  width: 'calc(100% / 2)',
  marginTop: '10vh',
  marginBottom: '10vh',
};

const modalTablet = {
  maxHeight: 'inherit',
  overflowY: 'scroll',
  width: '90%',
  marginTop: '10vh',
  marginBottom: '10vh',
};

const PrivetConditions = (props) => {
  const { display, onClickOutside, device } = props;
  const isTablet = device === 'tablet';
  const isMobile = device === 'mobile';
  return (
    display && (
    <Modal
      style={isTablet || isMobile ? modalTablet : modalDesktop}
      onClickOutside={onClickOutside}
      closeButton
    >
      <div className={s.container}>
        <div className={s.container__wrapper}>
          <h4>Politique « Vie Privée » de l’avenir advertising S.A Politique</h4>
          <ul className={s.container__wrapper__items}>
            <li>
              <h5>1. Objectif</h5>
              <p>Le présent document constitue la politique « Vie Privée » mise en œuvre L’Avenir Advertising S.A. dans le cadre de ses activités.</p>
              <p>La protection de votre vie privée et de vos données à caractère personnel est d’une importance capitale pour L’Avenir Advertising S.A.</p>
              <p>Cette politique « Vie Privée » est rédigée afin de garantir le respect du Règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la Directive 95/46/EC (Règlement général sur la protection des données, ou RGPD).</p>
              <p>Cette politique « Vie Privée » a pour but de vous informer de manière complète sur le sujet et explique comment nous collectons, utilisons et conservons vos données à caractère personnel.</p>
            </li>
            <li>
              <h5>2. Quelle est la portée de cette politique ?</h5>
              <p>Que recouvre le « traitement de vos données » et qui en est responsable ?</p>
              <p>Nous recueillons et utilisons uniquement les données personnelles qui sont nécessaires dans le cadre de nos activités et qui nous permettent de vous proposer des produits et services de qualité.</p>
              <p>L’Avenir Advertising S.A., ayant son siège social à 5004 Bouge route de Hannut 38 est Responsable du traitement des données à caractère personnel qu’elle est amenée à traiter. Nous sommes en conséquence votre partenaire ainsi que celui des autorités de contrôle (par exemple « l’Autorité de la Protection des Données ») pour toute question relative à l’utilisation de vos données par notre société.</p>
              <p>Pour certains services, nous faisons appel à des tiers spécialisés qui, dans certains cas, agissent comme sous-traitants. Ceux-ci doivent alors suivre nos directives et respecter notre politique « Vie Privée ». Dans d’autres cas, ces tiers sont aussi co-responsables du traitement et doivent de leur côté respecter leurs obligations légales en la matière. Nous nous assurons que ces sous-traitants ne reçoivent que les données strictement nécessaires pour exécuter leur partie du contrat. Nous intervenons aussi en qualité de sous-traitant pour d’autres entités juridiques distinctes de L’Avenir Advertising S.A., Dans ce cas, ce sont ces entités qui sont Responsables du traitement des données à caractère personnel. Nous suivons alors leurs instructions.</p>
            </li>
            <li>
              <h5>3. Quelles données sont couvertes par notre politique?</h5>
              <p>Les données couvertes par la présente politique sont les données à caractère personnel des personnes physiques, c’est-à-dire des données qui permettent directement ou indirectement d’identifier une personne.</p>
              <p>Dans le cadre de vos relations et interactions avec L’Avenir Advertising S.A., nous pouvons être amenés à collecter différentes données à caractère personnel telles que :</p>
              <ul>
                <li>des données d’identification et de contact (exemples : civilité, nom, adresse, date de naissance, numéro de téléphone, adresse mail, adresse IP);</li>
                <li>données bancaires, financières et transactionnelles (exemples : coordonnées bancaires, numéros de compte, données relatives aux virements y compris la communication, et, de façon générale, toutes données enregistrées lors de vos transferts bancaires);</li>
                <li>données relatives à vos comportements et habitudes concernant l’utilisation de nos canaux (exemples : nos sites Internet, nos applications pour tablette et smartphone);</li>
                <li>données relatives à vos préférences et intérêts, que vous nous communiquez directement ou indirectement, par exemple via participation à nos concours ou événements,…;</li>
                <li>données provenant de vos interactions sur nos pages dédiées sur les réseaux sociaux.</li>
              </ul>
              <p>Nous ne traitons jamais de données relatives à vos origines raciales ou ethniques, vos opinions politiques, votre religion, vos convictions philosophiques ou l’appartenance syndicale, vos données génétiques, votre vie sexuelle ou orientation sexuelle à moins que la législation nous l’impose ou que cela résulte de l’exécution que vous faites de nos produits et services (exemple : vous mentionnez ce type d’information).</p>
            </li>
            <li>
              <h5>4. Principes directeurs pour traiter les données à caractère personnel</h5>
              <p>L’Avenir Advertising S.A. respectera entre autres les principes suivants lors des traitements de données à caractère personnel dans le cadre de la gestion et de l’exécution de ses engagements :</p>
              <ul>
                <li>Traitement de données licite : L’Avenir Advertising S.A. traite les données à caractère personnel d’une manière licite dans le cadre de ses activités ;</li>
                <li>Finalités déterminées et limitation des finalités : L’Avenir Advertising S.A. collecte et traite les données à caractère personnel pour les finalités licites déterminées ci-après ;</li>
                <li>Minimisation du traitement des données : L’Avenir Advertising S.A. limite le traitement des données à caractère personnel à ce qui est nécessaire dans le cadre de ses activités ;</li>
                <li>Exactitude des données à caractère personnel : L’Avenir Advertising S.A. prend toutes les mesures raisonnables pour veiller à ce que les données à caractère personnel soient exactes et qu’elles soient rectifiées et/ou effacées sans tarder si elles n’apparaissent plus exactes ;</li>
                <li>Limitation du traitement et de la conservation L’Avenir Advertising S.A. ne traitera et ne conservera pas plus longtemps que nécessaire à la réalisation de ses activités les données à caractère personnel ;</li>
                <li>Mesures de sécurité : L’Avenir Advertising S.A. prend les mesures techniques et/ou organisationnelles nécessaires et adéquates pour la sécurité des données à caractère personnel.</li>
              </ul>
            </li>
            <li>
              <h5>5. Quand vos données à caractère personnel sont-elles collectées ?</h5>
              <p>Les données que nous utilisons peuvent être collectées directement auprès de vous ou obtenues des sources suivantes, dans le but de vérifier ou d’enrichir nos bases de données :</p>
              <ul>
                <li>des publications/bases de données rendues accessibles par les autorités officielles (exemple: le Moniteur Belge) ;</li>
                <li>nos entreprises clientes, partenaires ou nos fournisseurs de services ;</li>
                <li>des sites internet/pages de réseaux sociaux contenant des informations que vous avez rendues publiques (exemple : votre site web ou réseau social) ;</li>
                <li>des bases de données rendues publiques par des tiers.</li>
              </ul>
              <p>Certaines de vos données peuvent également notamment être collectées par L’Avenir Advertising S.A.:</p>
              <ul>
                <li>quand vous devenez clients ou fournisseurs;</li>
                <li>quand vous vous enregistrez pour utiliser nos services en ligne (à chaque identification ou utilisation) ;</li>
                <li>quand vous remplissez les formulaires et contrats que nous vous soumettons ;</li>
                <li>quand vous utilisez nos services et produits suite à la signature d’un contrat ;</li>
                <li>quand vous vous abonnez à nos newsletters, répondez à nos concours ;</li>
                <li>quand vous nous contactez par les différents canaux mis à votre disposition ;</li>
                <li>quand vos données sont publiées ou transmises par des tiers autorisés ou des fournisseurs professionnels de données ;</li>
                <li>quand vous êtes filmés par nos caméras de surveillance situées dans et aux abords de nos locaux/immeubles.</li>
              </ul>
              <p>Les images sont enregistrées uniquement dans le but de préserver la sécurité des biens et des personnes ainsi que de prévenir les abus, fraudes et autres infractions envers nos clients et/ou notre personnel (leur présence est signalée par des autocollants munis de nos coordonnées de contact).</p>
            </li>
            <li>
              <h5>6. Sur quel fondement et pourquoi utilisons-nous vos données personnelles ?</h5>
              <p>Nous traitons vos données à caractère personnel en vue de diverses finalités. Pour chaque traitement, seules les données pertinentes par rapport à la finalité poursuivie sont traitées.</p>
              <p>De manière générale, nous utilisons vos données à caractère personnel :</p>
              <ul>
                <li>dans le cadre de l’exécution d’un contrat ou la prise de mesures précontractuelles ;</li>
                <li>afin de nous conformer aux dispositions légales et réglementaires auxquelles nous sommes soumis ;</li>
                <li>pour des raisons qui relèvent de l’intérêt légitime de la société (voyez des illustrations ci-dessous). Lorsque nous effectuons ce type de traitement, nous veillons à préserver l’équilibre entre cet intérêt légitime et le respect de votre vie privée ;</li>
                <li>quand nous avons obtenu votre consentement.</li>
              </ul>
              <p>Les données à caractère personnel sont traitées par L’Avenir Advertising S.A. pour les finalités qui incluent, mais ne sont pas limitées à :</p>
              <ul>
                <li>vous fournir des informations relatives à nos produits et services ;</li>
                <li>vous assister et répondre à vos demandes ;</li>
                <li>permettre la bonne exécution des conventions conclues ;</li>
                <li>assurer la gestion financière et comptable de L’Avenir Advertising S.A.</li>
                <li>assurer une bonne gestion de la clientèle, du matériel et des fournisseurs ;</li>
                <li>effectuer des études de marché et établir des profils d’utilisation, réaliser des opérations d’information et/ou de promotion sur les produits et services, ceux des sociétés de son groupe et/ou de ses partenaires commerciaux et pour autant que vous y ayez marqué votre consentement;</li>
                <li>réaliser une amélioration des produits et services existants (ou en cours de développement) grâce à des enquêtes auprès de clients actuels ou potentiels des statistiques, des tests, des commentaires que vous nous adressez directement ou que vous publiez sur nos sites webs ;</li>
                <li>répondre aux obligations légales parmi lesquelles les réponses aux demandes officielles d’autorités publiques ou judiciaires dûment autorisées ;</li>
                <li>détecter et prévenir des abus et des fraudes : nous traitons et gérons des données de contact et de sécurité (lecteur de carte, mot de passe…) afin de valider, suivre et assurer la sécurité des transactions et communications via nos canaux à distance ;</li>
                <li>afin d’assurer la fourniture de services et produits en faisant appel à des sous-traitants ;</li>
                <li>suivi de nos activités (mesure des ventes, du nombre de rendez-vous, du nombre d’appels, de visites sur notre site internet…) ;</li>
                <li>amélioration de la qualité du service individuel à nos clients ;</li>
                <li>prospection relative à des produits et services de L’Avenir Advertising S.A.;</li>
                <li>assurer la sécurisation de nos locaux et infrastructures, ainsi que des personnes en ces lieux.</li>
              </ul>
            </li>
            <li>
              <h5>8. Combien de temps conservons-nous vos données ?</h5>
              <p>L’Avenir Advertising S.A. s’engage à adopter les mesures techniques, physiques et organisationnelles nécessaires et adéquates pour protéger les données à caractère personnel contre les accès non autorisés, le traitement illicite et non autorisé, la perte ou le dommage accidentel, et la destruction non autorisée. Ces mesures sont régulièrement évaluées et si nécessaire actualisées en vue de garantir une protection maximale des données à caractère personnel des personnes concernées. En cas de violation ou de fuite informatique, comme décrite ci-dessous, L’Avenir Advertising S.A. prend les mesures nécessaires/adéquates pour en constater l’étendue et les conséquences, y mettre fin le plus vite possible et, le cas échéant, limiter son impact pour les personnes concernées.</p>
            </li>
            <li>
              <h5>10. Quels sont vos droits et comment les exercer ?</h5>
              <ul className={s.container__wrapper__items__subItems}>
                <li>
                  <h6>10.1 Droits des personnes concernées</h6>
                  <p>Conformément à la règlementation applicable, vous disposez de différents droits :</p>
                  <ul>
                    <li>Le droit de demander l’accès aux données à caractère personnel (A)</li>
                    <li>Le droit à la rectification (A)</li>
                    <li>Le droit à l’effacement des données(A)</li>
                    <li>Le droit de s’opposer au traitement (B)</li>
                    <li>Le droit de retirer son consentement (B)</li>
                    <li>Le droit de demander une limitation du traitement (B)</li>
                    <li>Le droit à la portabilité des données (C)</li>
                  </ul>
                  <p>A. Droit d’accès, de rectification et d’effacement</p>
                  <p>Toute personne concernée a le droit de faire une demande d’accès. Si une personne concernée exerce ce droit, L’Avenir Advertising S.A. est tenue de lui fournir des informations à ce sujet, en ce compris :</p>
                  <ul>
                    <li>de donner une description et une copie des données à caractère personnel;</li>
                    <li>d’informer la personne concernée des raisons pour lesquelles L’Avenir Advertising S.A. traite ces données.</li>
                  </ul>
                  <p>Si des données sont inexactes ou incomplètes, la personne concernée peut demander de les rectifier voire les rectifier elle-même si le système d’information concerné le permet. Dans certaines circonstances, la personne concernée peut, conformément à la règlementation en matière de protection des données, demander l’effacement d’une donnée à caractère personnel la concernant, entre autres si la donnée à caractère personnel n’est plus nécessaire pour les finalités pour lesquelles elle avait été collectée ou traitée. L’Avenir Advertising S.A. peut cependant refuser d’effacer ces données, par exemple pour l’introduction, la mise en œuvre ou la preuve d’un droit en justice.</p>
                  <p>B. Droit d’opposition et de limitation au traitement de vos données et droit de retirer votre consentement</p>
                  <p>Vous avez le droit de vous opposer à certains traitements de vos données à caractère personnel que nous souhaiterions effectuer. Vous avez notamment le droit de vous opposer, sans justification, à l’utilisation de vos données à des fins de prospection. Vous pouvez également demander la limitation du traitement de vos données.</p>
                  <p>Ce droit ne peut cependant s’exercer que dans certaines conditions :</p>
                  <ul>
                    <li>1) votre demande doit être datée et signée ;</li>
                    <li>2) pour les cas autres que l’opposition à des fins de prospection, vous devez avoir des raisons sérieuses et légitimes tenant à votre situation particulière pour vous opposer à ce que le traitement ait lieu. En cas d’opposition justifiée, le traitement en question ne pourra plus porter sur ces données.</li>
                  </ul>
                  <p>Par contre, vous ne pouvez pas vous opposer à un traitement nécessaire à l’exécution d’un contrat conclu avec vous ou à l’exécution des mesures précontractuelles prises à votre demande; ni au respect de toute disposition légale ou réglementaire à laquelle nous sommes soumis.</p>
                  <p>Si vous avez donné votre consentement au traitement de vos données personnelles, vous avez le droit de retirer ce consentement à tout moment.</p>
                  <p>C. Le droit à la portabilité</p>
                  <p>Lorsque cela est nécessaire et dans la mesure où cela est applicable, la personne concernée peut demander de recevoir certaines données à caractère personnel qu’elle a fournies à L’Avenir Advertising S.A. dans le cadre de la gestion et de l’exécution de ses activités, et de transférer ces données vers un autre Responsable du traitement. Lorsque cela est techniquement possible, la personne concernée peut demander à L’Avenir Advertising S.A. de transférer directement ces données vers un autre Responsable du traitement.</p>
                </li>
                <li>
                  <h6>10.2 A qui vous adresser ?</h6>
                  <p>Si la personne concernée souhaite exercer ses droits relativement à ses données à caractère personnel, elle peut en faire part à :</p>
                  <ul>
                    <li>Au C.P.D (Coordinateur de la Protection des Données) :</li>
                    <ul>
                      <li>par mail : privacy@lavenir.net</li>
                      <li>par courrier : L’Avenir Advertising S.A – à l’attention du Coordinateur de la Protection des Données – Route de Hannut 38, 5004 Bouge</li>
                    </ul>
                  </ul>
                  <p>Dans ce cadre, votre demande doit nous parvenir datée et signée, accompagnée d’une copie/scan de votre carte d’identité. Conformément à la règlementation, vous êtes en droit d’introduire une réclamation auprès de l’Autorité de contrôle compétente.</p>
                </li>
              </ul>
            </li>
            <li>
              <h5>11. Transfert des données en dehors de l’EEE</h5>
              <p>En cas de transferts internationaux émanant de l’EEE vers un pays tiers pour lequel la Commission européenne a rendu une décision d’adéquation reconnaissant à ce pays un niveau de protection des données personnelles équivalent à celui prévu par la législation de l’EEE, vos données personnelles seront transférées sur ce fondement. Pour des transferts vers des pays en dehors de l’EEE pour lesquels la Commission européenne n’a pas rendu de décision d’adéquation, nous nous appuyons soit sur une dérogation applicable à la situation (ex : en cas de paiement à l’international, le transfert est nécessaire à l’exécution du contrat), soit sur le fait que le destinataire des données a accepté de traiter les données à caractère personnel conformément aux « Clauses Contractuelles Types » établies par la Commission européenne pour les Responsables des données ou les sous-traitants. Pour obtenir une copie de ces textes ou savoir comment y accéder, vous pouvez adresser une demande écrite de la manière indiquée dans la Section 10.2.</p>
            </li>
            <li>
              <h5>12. Violation de données à caractère personnel</h5>
              <p>A. Mention des violations relatives aux données à caractère personnel</p>
              <p>Les utilisateurs autorisés doivent veiller, dans l’exercice de leur fonction, à éviter des incidents (volontaires ou non) qui peuvent porter atteinte à la vie privée des personnes concernées. En cas de violation de données à caractère personnel, des mesures adéquates sont prises le plus rapidement possible pour minimiser le risque de dommage pour les personnes concernées ainsi que pour L’Avenir Advertising S.A. (atteinte à la réputation, sanctions imposées, …). Dans tous les cas, tous les utilisateurs autorisés, ainsi que toutes les autres personnes qui consultent, utilisent ou gèrent des informations de L’Avenir Advertising S.A. doivent signaler immédiatement toute violation de la sécurité et les incidents en lien avec la sécurité des informations au D.P.D de sorte qu’une analyse puisse immédiatement être faite, les mesures nécessaires prises et pour savoir si la violation doit être signalée à l’Autorité de Protection des Données et/ou aux personnes concernées. Lorsque le signalement est réalisé par courriel, il est important que celui-ci soit envoyé au D.P.D (voir Section 10.2) et qu’il soit expressément indiqué dans l’objet du courriel qu’il s’agit d’un message avec urgence élevée à propos d’une possible violation en lien avec les données à caractère personnel. L’information doit contenir une description complète et détaillée de l’incident, en ce compris l’identité de la personne qui fait le signalement (nom, prénom, adresse, courriel (le cas échéant) et numéro de téléphone), de quel type d’incident il s’agit, et combien de personnes sont concernées.</p>
              <p>B. Enquête et analyse des risques</p>
              <p>
                En principe, dans un délai de 24 heures après que l’incident ou la violation a été constaté par L’Avenir Advertising S.A. ou signalé par un sous-traitant, un utilisateur autorisé, un destinataire, une personne concernée ou un tiers, une enquête sera entamée par L’Avenir Advertising S.A..
                <br />
                L’enquête indiquera quelle est la nature de l’incident, le type de données visées et si spécifiquement des données à caractère personnel sont impactées (et dans l’affirmative, qui sont les personnes concernées et combien de données à caractère personnel sont touchées). L’enquête déterminera s’il s’agit d’une violation ou non des données à caractère personnel.
                <br />
                S’il s’agit d’une violation, une analyse des risques sera effectuée pour savoir quelles sont (peuvent être) les conséquences possibles de la violation, et en particulier les impacts (possibles) pour les personnes concernées.
                <br />
                L’Avenir Advertising S.A. décidera ensuite, sur la base du caractère de la violation, s’il y a ou non une obligation d’effectuer une notification à l’Autorité de Protection des Données et/ou à la personne concernée.
              </p>
              <p>C. Documentation des violations</p>
              <p>
                Toutes les violations seront documentées dans un registre. Le registre détaillera la cause principale de l’incident et les facteurs contributifs, la chronologie des événements, les actions en réponse, les recommandations et les leçons apprises en vue d’identifier les domaines qui nécessitent une amélioration. Les changements recommandés à apporter aux systèmes, et procédures seront documentés et mis en place aussi vite que possible.
                <br />
                Dans le cadre de sa mission de surveillance du respect de la règlementation en matière de protection des données le D.P.D examinera également les suites données au traitement de la violation consignée dans le rapport.
              </p>
            </li>
            <li>
              <h5>13. Délégué à la protection des données (« D.P.D »)</h5>
              <p>Le groupe NETHYS a désigné pour toutes ses filiales, dont L’Avenir Advertising S.A., un délégué à la protection des données qui peut être contacté par courrier (DPO Office NETHYS – rue Louvrex, 95 à 4000 Liège) ou par mail (privacy@nethys.be).</p>
              <p>Ce délégué à la protection des données est compétent :</p>
              <ul>
                <li>Pour informer et conseiller NETHYS et ses différentes entités, ainsi que les membres du personnel, les administrateurs ainsi que la Direction quant aux obligations qui leur incombent en vertu de la Législation et règlementation en matière de protection des données ;</li>
                <li>Pour contrôler le respect de la Législation et règlementation en matière de protection des données et de la politique de traitement et de protection des données à caractère personnel dans le cadre des activités de NETHYS et de ses entités tels que repris dans la présente politique, y compris en ce qui concerne la répartition des responsabilités, la sensibilisation et la formation des personnes participant aux opérations de traitement, et les audits s’y rapportant ;</li>
                <li>Pour dispenser des conseils, sur demande, en ce qui concerne l’analyse d’impact relative à la protection des données à caractère personnel et d’en vérifier l’exécution;</li>
                <li>Pour coopérer avec l’Autorité de Protection des Données ;</li>
                <li>
                  Pour faire office de point de contact pour :
                  <ul>
                    <li>Les personnes concernées, qui peuvent contacter le délégué à la protection des données pour toutes les questions liées au traitement de leurs données à caractère personnel et à l’exercice de leurs droits ;</li>
                    <li>Toute personne qui constate un incident ou une violation en lien avec le traitement des données à caractère personnel dans le cadre des activités de NETHYS et de ses entités et qui doivent entre autres en informer le Délégué à la protection des données ;</li>
                    <li>L’Autorité de Protection des Données en ce qui concerne les questions liées aux traitements de données à caractère personnel ;</li>
                  </ul>
                </li>
                <li>Pour tenir dûment compte du risque associé aux opérations de traitement, compte tenu de la nature, de la portée, du contexte et des finalités du traitement ;</li>
                <li>Pour toute autre mission ou tâche qu’il se verrait confier.</li>
              </ul>
            </li>
            <li>
              <h5>14. Comment prendre connaissance de cette politique et de ses modifications ?</h5>
              <p>
                Dans un monde en constante évolution technologique, nous actualiserons régulièrement la Politique « Vie Privée ».
                <br />
                Nous vous invitons à prendre connaissance de la dernière version de ce document sur nos sites et nous vous informerons de toute modification substantielle par le biais de nos sites ou par nos modes de communications habituels.
              </p>
            </li>
            <li>
              <h5>15. Comment nous contacter ?</h5>
              <p>
                Si vous avez des questions concernant l’utilisation de vos données personnelles visée par la présente politique, vous pouvez contacter notre coordinateur à la protection des données (C.P.D) par courrier adressé à « L’Avenir Advertising S.A.», à l’attention du Coordinateur à la Protection des Données – Route de Hannut 38 à 5004 Bouge ou par e-mail à l’adresse privacy@lavenir.net.
                <br />
                En ce qui concerne les Membres de notre personnel, ceux-ci peuvent également consulter la « Politique Vie Privée R.H » sur l’intranet de la société.
              </p>
            </li>
          </ul>
        </div>
        <p className={s.container__footnote}>La présente Politique « Vie Privée » est applicable à dater du 25 mai 2018.</p>
      </div>
    </Modal>
    ));
};

PrivetConditions.defaultProps = {
  display: false,
};


const mapStateToProps = state => ({
  device: state.device,
});

export default connect(mapStateToProps)(PrivetConditions);
