import * as types from '../actions/actionTypes';

const device = (state = {}, action) => {
  switch (action.type) {
    case types.SET_DEVICE_TYPE:
      return action.device;
    default:
      return state;
  }
};

export default device;
