/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaUser, FaUserCheck } from 'react-icons/fa';

import s from './style.module.scss';

class ConnectionIndicator extends React.Component {
  handleClick() {
    const { history, user, location } = this.props;
    if (location.pathname === '/connection') return;
    history.push(user.isConnected ? '/personalPage' : '/connection', {
      nextPage: history.location,
    });
  }

  render() {
    const { user, device } = this.props;
    const isTablet = device === 'tablet';
    const isMobile = device === 'mobile';

    return user.isConnected ? (
      <div
        className={s.connected}
        onClick={() => this.handleClick()}
        onKeyPress={() => this.handleClick()}
        role="button"
        tabIndex="0"
      >
        <FaUserCheck
          size={isMobile ? 22 : isTablet ? 26 : 20} // eslint-disable-line no-nested-ternary
          style={{ marginRight: '5px' }}
        />
      </div>
    ) : (
      <div
        className={s.disconnected}
        onClick={() => this.handleClick()}
        onKeyPress={() => this.handleClick()}
        role="button"
        tabIndex="0"
      >
        <FaUser
          style={{ marginRight: '5px' }}
          size={isMobile ? 18 : isTablet ? 26 : 20} // eslint-disable-line no-nested-ternary
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  device: state.device,
});

export default withRouter(connect(mapStateToProps)(ConnectionIndicator));
