import React from 'react';
import {
  Map as LeafletMap, TileLayer, Marker, Popup,
} from 'react-leaflet';

import './style.scss';

const Map = () => (
  <LeafletMap
    center={[50.476020, 4.908040]}
    zoom={8}
    maxZoom={15}
    attributionControl
    zoomControl
    doubleClickZoom
    scrollWheelZoom
    dragging
    animate
    easeLinearity={0.35}
  >
    <TileLayer
      url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
    />
    <Marker position={[50.476020, 4.908040]}>
      <Popup>
            Editions de l&apos;avenir
      </Popup>
    </Marker>
  </LeafletMap>
);

export default Map;
