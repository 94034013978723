/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TextButton = (props) => {
  const {
    handleClick, fitToContainer, color, rounded, text, type, style,
  } = props;
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      onClick={handleClick}
      onKeyPress={handleClick}
      className={`text-button-container ${fitToContainer ? 'fit' : null} ${color || null} ${rounded ? 'rounded' : null}`}
      style={style}
    >
      <span className="text-button-text">{text}</span>
    </button>
  );
};

TextButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

TextButton.defaultProps = {
  type: 'button',
};

export default TextButton;
