/* eslint-disable react/prop-types */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  MdMail,
  MdFlag,
} from 'react-icons/md';

import Map from '../../components/Map';
import TextButton from '../../components/TextButton';
import RenderField from '../../components/RenderField/index';
import Layout from '../../components/Layout';

import { validate } from '../../helpers/validate';
import { contact } from '../../services/contact';

import './style.scss';

class Contact extends React.Component {
  state = {
    showForm: true,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  sendMessage = async (body) => {
    this.setState({
      showForm: false,
    });
    try {
      await contact(body);
    } catch (error) {
      throw error;
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const { showForm } = this.state;
    return (
      <Layout
        cover="/assets/images/covers/contact.jpg"
        title="Contactez-nous"
      >
        <div className="contact-container">
          <div className="contact-content-container">
            <Map />
            <div className="contact-content-info">
              <h6>L&apos;Avenir Advertising</h6>
              <p>
                <MdMail />
                {' '}
                webshop@lavenir.net
              </p>
              <p>
                <MdFlag />
                {' '}
                Route de Hannut 38 - 5004 Bouge
              </p>
              <p>
                Ouvert du lundi au vendredi,
                <br />
                {' '}
                de 9h à 17h.
              </p>
            </div>
          </div>
          {showForm
            ? (
              <form className="contact-form-container">
                <h6>Envoyez-nous un message</h6>
                <div className="form-container--row">
                  <Field
                    name="firstname"
                    type="text"
                    component={RenderField}
                    placeholder="Jean"
                    label="Prénom"
                  />
                  <Field
                    name="lastname"
                    type="text"
                    component={RenderField}
                    placeholder="Dupont"
                    label="Nom"
                  />
                </div>
                <div className="form-container--row">
                  <Field
                    name="phone"
                    type="number"
                    component={RenderField}
                    placeholder="040000000"
                    label="Téléphone"
                  />
                  <Field
                    name="municipality"
                    type="text"
                    component={RenderField}
                    placeholder="Province"
                    label="Province"
                  />
                </div>
                <div className="form-container--row">
                  <Field
                    name="mail"
                    type="mail"
                    component={RenderField}
                    placeholder="jeandupont@gmail.com"
                    label="E-mail"
                  />
                  <Field
                    name="object"
                    type="text"
                    component={RenderField}
                    placeholder="Sujet"
                    label="Sujet"
                  />
                </div>
                <Field
                  name="message"
                  type="textarea"
                  component={RenderField}
                  placeholder="Message..."
                  label="Message"
                  textarea
                />
                <TextButton
                  text="Envoyer"
                  handleClick={handleSubmit(data => this.sendMessage(data))}
                  fitToContainer
                />
              </form>
            )
            : (
              <div className="contact-confirm-message">
                <h5>Message envoyé !</h5>
                <h5>Nous vous répondons dans les plus bref délais.</h5>
              </div>
            )

          }
        </div>
      </Layout>
    );
  }
}

export default reduxForm({
  form: 'contact',
  validate,
})(Contact);
