/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { withRouter } from 'react-router-dom';
import s from './style.module.scss';

class CardSelector extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  setCard(e) {
    const { history } = this.props;
    switch (e.target.value) {
      case 'credit': return history.push('/payment/credit');
      case 'debit': return history.push('/payment/debit');
      case 'paypal_option': return history.push('/payment/paypal');
      default: return null;
    }
  }

  render() {
    const { location } = this.props;
    return (
      <div onChange={e => this.setCard(e)} className={s.container}>
        <div className={s.container__tooltip}>Type de carte : </div>
        <input
          type="radio"
          value="credit"
          name="card"
          id="credit"
          defaultChecked={location.pathname === '/payment/credit'}
        />
        <label htmlFor="credit">
          <img src="/assets/images/credit.png" alt="crédit" />
        </label>
        <input
          type="radio"
          value="debit"
          name="card"
          id="debit"
          defaultChecked={location.pathname === '/payment/debit'}
        />
        <label htmlFor="debit">
          <img src="/assets/images/debit.png" alt="débit" />
        </label>
        <input
          type="radio"
          value="paypal_option"
          name="card"
          id="paypal_option"
          defaultChecked={location.pathname === '/payment/paypal'}
        />
        <label htmlFor="paypal_option">
          <img src="/assets/images/paypal.png" alt="débit" />
        </label>
      </div>
    );
  }
}

export default withRouter(CardSelector);
