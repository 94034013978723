import * as types from './actionTypes';

export function setEdition(edition) {
  return {
    type: types.SET_EDITION,
    edition,
  };
}

export const setFromPrice = fromPrice => ({
  type: types.SET_FROM_PRICE,
  fromPrice,
});

export function setRegion(region) {
  return {
    type: types.SET_REGION,
    region,
  };
}

export function setOrderDataEdition(editionId) {
  return {
    type: types.SET_ORDER_DATA_EDITION,
    editionId,
  };
}

export function setSelectedEditionDates(selectedEditionDates) {
  return {
    type: types.SET_SELECTED_EDITION_DATES,
    selectedEditionDates,
  };
}

export function setOrderDataEditionDates(publicationDateIds) {
  return {
    type: types.SET_ORDER_DATA_EDITION_DATES,
    publicationDateIds,
  };
}

export function setSelectedEditionFormat(format) {
  return {
    type: types.SET_FORMAT,
    format,
  };
}

export function setOrderDataFormat(editionFormatPriceId) {
  return {
    type: types.SET_ORDER_DATA_FORMAT,
    editionFormatPriceId,
  };
}

export function setFormatPrice(formatPrice) {
  return {
    type: types.SET_FORMAT_PRICE,
    formatPrice,
  };
}

export function setMaterialType(materialType) {
  return {
    type: types.SET_MATERIAL_TYPE,
    materialType,
  };
}

export function setMaterialFiles(materialFiles) {
  return {
    type: types.SET_MATERIAL_FILES,
    materialFiles: materialFiles.map(f => ({
      file: f.inputFile, content: f.file, name: f.name, format: f.type,
    })),
  };
}

export function setCurrentOrderError(error) {
  return {
    type: types.SET_ORDER_ERROR,
    error,
  };
}

export function clearCurrentOrderError() {
  return {
    type: types.CLEAR_ORDER_ERROR,
    error: { error: false, errorMessage: '' },
  };
}

export function setInvoiceDemand(value) {
  return {
    type: types.SET_INVOICE_DEMAND,
    invoiceRequested: value,
  };
}

export function setGeneralConditionsAccepted(value) {
  return {
    type: types.SET_GENERAL_CONDITIONS_ACCEPTED,
    generalConditionsAccepted: value,
  };
}

export const addOrderRequest = () => ({
  type: types.ADD_ORDER_REQUEST,
  payload: { },
});

export const resetOrderState = () => ({
  type: types.RESET_ORDER_REDUCER,
  payload: { },
});

export const addOrderSuccess = order => ({
  type: types.ADD_ORDER_SUCCESS,
  payload: { order },
});

export const addOrderFailure = error => ({
  type: types.ADD_ORDER_FAILURE,
  payload: { error },
});

export const setSavedOrder = savedOrder => ({
  type: types.SET_SAVED_ORDER,
  payload: { savedOrder },
});
