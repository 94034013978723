/* eslint-disable func-names */
import fetch from 'isomorphic-fetch';
import { fetchWithCredentials } from 'helpers/authRequest';
import * as userActions from '../actions/userActions';
import { endpoints, getValidateAccount } from '../api';


export const getMe = () => async (dispatch) => {
  try {
    const res = await fetchWithCredentials(endpoints.getMe, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    if (
      res.headers.get('content-type') === null
      || res.headers.get('content-type').indexOf('application/json') === -1
    ) {
      throw res.statusText;
    }
    const userData = await res.json();
    if (!res.ok) throw userData;
    dispatch(userActions.getMeSuccess(userData));
    return userData;
  } catch (error) {
    throw error;
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch(userActions.logoutUserSuccess());
};

export const initApp = () => async (dispatch, getState) => {
  try {
    if (getState().user.authToken !== null) {
      await dispatch(getMe());
    }
    dispatch(userActions.isReady());
  } catch (e) {
    if (e === 'Unauthorized') {
      await dispatch(logoutUser());
    }
    // Always dispatch isReady
    dispatch(userActions.isReady());
  }
};

export const loginUser = body => async (dispatch) => {
  try {
    const res = await fetch(endpoints.authenticaterUser, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await res.json();
    if (!res.ok) throw data;
    localStorage.setItem('token', data.token);
    const userData = await dispatch(getMe());
    userData.authToken = data.token;
    dispatch(userActions.loginUserSuccess());
    return userData;
  } catch (error) {
    throw error;
  }
};

export const signUpUser = body => async (dispatch) => {
  try {
    const res = await fetch(endpoints.registerUser, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    if (
      res.headers.get('content-type') === null
      || res.headers.get('content-type').indexOf('application/json') === -1
    ) {
      if (res.ok) return dispatch(userActions.signUpUserSuccess(body));
      throw res.statusText;
    }
    const data = await res.json();
    throw data;
  } catch (error) {
    throw error;
  }
};

export const validateUser = async (email, code, url) => {
  try {
    const res = await fetch(getValidateAccount(email, code, url));
    if (
      res.headers.get('content-type') === null
      || res.headers.get('content-type').indexOf('application/json') === -1
    ) {
      if (res.ok) return true;
      throw res.statusText;
    }
    const data = await res.json();
    throw data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    const res = await fetch(`${endpoints.resetpassword}/?userMail=${email}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    if (
      res.headers.get('content-type') === null
      || res.headers.get('content-type').indexOf('application/json') === -1
    ) {
      if (res.ok) return true;
      throw res.statusText;
    }
    const data = await res.json();
    throw data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = userInfo => async (dispatch) => {
  try {
    const res = await fetchWithCredentials(endpoints.updateMe, {
      method: 'PUT',
      body: JSON.stringify(userInfo),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const userData = await res.json();
    if (!res.ok) throw userData;
    dispatch(userActions.updateMeSuccess(userData));
    return userData;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = (newPassword, userId) => async () => {
  try {
    const res = await fetchWithCredentials(`${endpoints.modifyPassword}/${userId}/password`, {
      method: 'PUT',
      body: JSON.stringify(newPassword),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const userData = await res;
    if (!res.ok) throw userData;
    return userData;
  } catch (error) {
    throw error;
  }
};
