import * as types from '../actions/actionTypes';

const defaultState = {
  isReady: false,
  isConnected: localStorage.getItem('token') !== null,
  authToken: localStorage.getItem('token'),
  // password: 'null',
  firstName: '',
  lastName: '',
  phone: '',
  mail: '',
  companyName: '',
  companyType: '',
  companyVAT: '',
  address: {
    street: '',
    number: '',
    municipalityId: '',
  },
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_USER_READY:
      return { ...state, isReady: true };
    case types.SIGNUP_USER_SUCCESS:
      return { ...state, mail: action.payload.user.mail };
    case types.SET_USER_EMAIL:
      return { ...state, mail: action.payload.mail };
    case types.LOGIN_USER_SUCCESS:
      return { ...state, isConnected: true };
    case types.GET_ME_SUCCESS:
      return { ...state, ...action.payload.user };
    // return { ...state, isConnected: true, ...action.user };
    case types.LOGOUT_USER_SUCCESS:
      localStorage.removeItem('token');
      return {
        ...defaultState, isConnected: false, authToken: null, isReady: true,
      };
    case types.UPDATE_USER_SUCCESS:
      return { ...state, ...action.payload.user };
    default:
      return state;
  }
};

export default user;
