import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';

import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

const store = configureStore();
const stripeKey = process.env.REACT_APP_STRIPE_KEY;


ReactDOM.render(
  <StripeProvider apiKey={stripeKey}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StripeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// TODO: change to register later
serviceWorker.unregister();
