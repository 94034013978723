/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PersonalInfo from 'containers/PersonalInfo';
import Orders from 'containers/Orders';
import { urlParam } from 'helpers/url';
import ModifyPassword from 'containers/ModifyPassword';
import TextButton from 'components/TextButton';
import { FaCheckCircle } from 'react-icons/fa';
import * as userService from '../../services/user';
import Layout from '../../components/Layout';
import Modal from '../../components/Modal';

import s from './style.module.scss';

const messages = {
  payment_success: 'Votre paiement a été effectué avec succès!',
};

const titles = {
  default: 'Espace personnel',
  missing_fields: 'Informations manquantes',
};

class PersonalPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      title: '',
    };
  }

  componentWillMount() {
    const { user, history } = this.props;
    if (!user.isConnected) {
      history.push('/connection', { nextPage: '/personalPage' });
    }
    this.setState({
      message: urlParam('message') ? urlParam('message') : '',
      title: urlParam('title') ? titles[urlParam('title')] : `Bonjour ${user.firstName} !`,
    });
    // Remove query string from url
    window.history.replaceState(null, null, window.location.pathname);
  }

  hideModal = () => {
    this.setState({ message: '' });
  }

  logOut() {
    const { userService, history } = this.props; // eslint-disable-line no-shadow
    userService.logoutUser().then(history.push('/'));
  }

  handleNavigation(path) {
    const { history } = this.props;
    history.push(path);
  }


  render() {
    const { device } = this.props;
    const modalSyles = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: '1 1 auto',
      maxHeight: '50vh',
      width: device === 'desktop' ? '50%' : '80%',
      marginTop: '10vh',
      marginBottom: '10vh',
      textAlign: 'center',
    };

    const { message, title } = this.state;
    const { currentPage } = this.props;

    const pages = () => {
      switch (currentPage) {
        case 'Mes Commandes':
          return <Orders />;
        case 'Informations personnelles':
          return <PersonalInfo />;
        case 'Modifier mot de passe':
          return <ModifyPassword />;
        default:
          return <Orders />;
      }
    };

    return (
      <Layout
        cover="/assets/images/covers/personnal.jpg"
        title={title}
      >
        <div className={s.container}>
          <ul className={s.container__menu}>
            <li
              onClick={() => this.handleNavigation('/personalPage')}
              onKeyPress={() => this.handleNavigation('/personalPage')}
              role="presentation"
            >
              Mes commandes
            </li>
            <li
              onClick={() => this.handleNavigation('/personalInfo')}
              onKeyPress={() => this.handleNavigation('/personalInfo')}
              role="presentation"
            >
              Informations personnelles
            </li>
            <li
              onClick={() => this.handleNavigation('/modifyPassword')}
              onKeyPress={() => this.handleNavigation('/modifyPassword')}
              role="presentation"
            >
              Modifier mot de passe
            </li>
            <li
              onClick={() => this.logOut()}
              onKeyPress={() => this.logOut()}
              role="presentation"
            >
              Se déconnecter
            </li>
          </ul>
          <div className={s.container__content}>
            <div className={s.container__content__title}>{currentPage}</div>
            <div className={s.container__content__info}>
              {message !== '' && (
                <Modal style={modalSyles} onClickOutside={this.hideModal}>
                  <div className={s.modal__content}>
                    <FaCheckCircle size={80} color="#2095a9" />
                    <h2 className={s.modal__content__title}>{messages[message]}</h2>
                    <TextButton handleClick={this.hideModal} text="OK" />
                  </div>
                </Modal>
              )}
              {pages()}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  userService: bindActionCreators(userService, dispatch),
});

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
  user: state.user,
  device: state.device,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PersonalPage),
);
