/* eslint-disable react/prop-types */
import React from 'react';

import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';

import TextButton from '../TextButton';
import RenderField from '../RenderField';

import { validate } from '../../helpers/validate';

// eslint-disable-next-line import/no-mutable-exports
const ValidateAccountForm = (props) => {
  const {
    handleSubmit, hidden, showMail,
  } = props;

  return (
    <>
      <form className={hidden ? 'form-hidden' : ''} onSubmit={handleSubmit}>
        {showMail && (
        <Field
          name="email"
          type="email"
          component={RenderField}
          placeholder="jean@mail.com"
          label="E-mail"
        />
        )}
        <Field
          name="code"
          type="text"
          component={RenderField}
          placeholder="Votre code d'activation"
          label="Code d'activation"
        />
        <div className="signUp-buttons--row">
          <TextButton
            text="Valider mon compte"
            type="submit"
            handleClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default withRouter(
  reduxForm({
    form: 'validate-account',
    validate,
  })(ValidateAccountForm),
);
