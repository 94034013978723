import { fetchWithCredentials } from 'helpers/authRequest';
import stripeErrors from 'helpers/stripe_errors';
import * as orderActions from '../actions/currentOrderActions';
import { endpoints, addMaterial, getAllOrders } from '../api';


export const uploadMaterial = (body, type, orderId) => async () => {
  try {
    const res = await fetchWithCredentials(addMaterial(orderId, type), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    if (
      res.headers.get('content-type') === null
      || res.headers.get('content-type').indexOf('application/json') === -1
    ) {
      if (res.ok) return true;
      throw res.statusText;
    }
    const data = await res.json();
    if (!res.ok) throw data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const addOrder = (body, files) => async (dispatch) => {
  try {
    dispatch(orderActions.addOrderRequest());
    const res = await fetchWithCredentials(endpoints.addOrder, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await res.json();
    if (!res.ok) throw data;
    let materialType;
    let images;
    let imgToSave = true;
    if (body.advertising.advertisingTypeCode === 'TBD') {
      materialType = 'material';
      images = files;
      imgToSave = images.length > 0;
    } else {
      materialType = 'finalimage';
      // If finalImage we send an object
      images = files[0];
    }
    if (imgToSave) {
      await dispatch(uploadMaterial(images, materialType, data.advertising.id));
    }
    dispatch(orderActions.addOrderSuccess(data));
    return data;
  } catch (error) {
    dispatch(orderActions.addOrderFailure(error));
    throw error.message;
  }
};

export const payOrder = body => async (dispatch) => {
  try {
    const res = await fetchWithCredentials(endpoints.payOrder, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await res.json();
    if (!res.ok) throw data;
    if (data.statut === 'succeeded') {
      dispatch(orderActions.resetOrderState());
    }
    return data;
  } catch (error) {
    let paymentErr = error;
    if (error.parameter) {
      if (error.parameter.decline_code) {
        paymentErr = new Error(stripeErrors[error.parameter.decline_code]);
      }
    }
    throw paymentErr;
  }
};

export const createPaypalOrder = orderId => async (dispatch) => {
  try {
    const res = await fetchWithCredentials(`${endpoints.createPaypalOrder}/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await res.json();
    if (!res.ok) throw data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const confirmPaypalOrder = (paypalOrderId, orderId) => async (dispatch) => {
  try {
    const res = await fetchWithCredentials(`${endpoints.confirmPaypalOrder}/${paypalOrderId}`, {
      method: 'POST',
      body: orderId,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await res.json();
    if (!res.ok) throw data;
    dispatch(orderActions.resetOrderState());
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAll = async (userId) => {
  try {
    const res = await fetchWithCredentials(getAllOrders(userId), {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await res.json();
    if (!res.ok) throw data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCurrentOrder = async (id) => {
  try {
    const res = await fetchWithCredentials(`${endpoints.currentOrder}/${id}?withImages=true&reduceImagesSize=true`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await res.json();
    if (!res.ok) throw data;
    return data;
  } catch (error) {
    throw error;
  }
};
