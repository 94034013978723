/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import s from './style.module.scss';

const modalDesktop = {
  maxHeight: 'inherit',
  overflowY: 'scroll',
  width: 'calc(100% / 2)',
  marginTop: '10vh',
  marginBottom: '10vh',
};

const modalTablet = {
  maxHeight: 'inherit',
  overflowY: 'scroll',
  width: '90%',
  marginTop: '10vh',
  marginBottom: '10vh',
};

const CGV = (props) => {
  const { display, onClickOutside, device } = props;
  const isTablet = device === 'tablet';
  const isMobile = device === 'mobile';
  return (
    display && (
    <Modal
      style={isTablet || isMobile ? modalTablet : modalDesktop}
      onClickOutside={onClickOutside}
      closeButton
    >
      <div className={s.container}>
        <div className={s.container__wrapper}>
          <h4>Conditions générales L’Avenir Advertising</h4>
          <p>Les conditions suivantes règlent l’exécution de tous les ordres d’insertion d’annonces au sens large du terme ainsi que la vente de bons de valeur, conﬁée par un tiers, ci-après nommé « donneur d’ordre » à la S.A. Editions de l’Avenir et/ou à la S.A. L’Avenir Advertising, ci-après dénommées pour la lisibilité «L’Avenir». Tout ordre à L’Avenir implique la renonciation aux conditions d’achat du donneur d’ordre. Sauf accord exprès et écrit de L’Avenir, l’on ne pourra s’écarter de ces conditions.</p>
          <ul>
            <li><b>1. RESPONSABILITE</b></li>
            <ul>
              <li>
                <b>1.1.</b>
                {' '}
                Les annonces et/ou les bons d’achat paraissent sous la responsabilité exclusive du donneur d’ordre.
              </li>
              <li>
                <b>1.2.</b>
                {' '}
                L’Avenir est toujours en droit de communiquer l’identité de son donneur d’ordre.
              </li>
              <li>
                <b>1.3.</b>
                {' '}
                L’Avenir est en droit d’insérer un numéro, un signe ou toute autre mention signalant clairement qu’il s’agit d’une annonce. 1.4 Le donneur d’ordre ne peut transmettre que du matériel pour lequel il dispose de tous les droits (d’auteurs, intellectuels, de reproduction des personnes photographiées, …).
              </li>
              <li>
                <b>1.5.</b>
                {' '}
                Le donneur d’ordre doit garantir L’Avenir et son éditeur responsable pour toute action en justice qui pourrait être lancée contre eux à l’occasion d’un ordre ou d’une vente d’un bon de valeur.
              </li>
              <li>
                <b>1.6.</b>
                {' '}
                Sauf accord exprès et écrit de L’Avenir, l’espace publicitaire ne peut d’aucune façon être revendu à ou mis à disposition d’un tiers.
              </li>
              <li>
                <b>1.7.</b>
                {' '}
                Lorsqu’il est délivré une ordonnance judiciaire de publication d’un texte, le donneur d’ordre est tenu de payer les frais de publication aux conditions tarifaires en vigueur.
              </li>
            </ul>
            <li>
              <b>2. DROIT DE REFUS.</b>
              {' '}
                L’Avenir se réserve le droit discrétionnaire de suspendre, d’arrêter ou de refuser la publication d’annonces, sans avoir à motiver sa décision et sans qu’aucune indemnité ne puisse lui être réclamée, même dans le cas où les services d’annonces auraient accepté cet ordre ou en auraient déjà entamé l’exécution. L’acceptation d’un ordre est toujours faite sous la condition suspensive d’approbation par la direction de L’Avenir.
            </li>
            <li>
              <b>3. DONNEUR D’ORDRE.</b>
              {' '}
                Si le donneur d’ordre n’est pas domicilié en Belgique, L’Avenir est en droit d’exiger la désignation d’une personne responsable ayant son domicile en Belgique.
            </li>
            <li>
              <b>4. SPECIFICATION</b>
            </li>
            <ul>
              <li>
                <b>4.1.</b>
                {' '}
                Selon les tarifs, un prix plus élevé sera mis en compte pour des souhaits spéciaux concernant le placement (des dates précises, un positionnement spécial ou une page particulière).
              </li>
              <li>
                <b>4.2.</b>
                {' '}
                Une insertion avec un emplacement préférentiel, un jour de parution spéciﬁque ou une exclusivité sur une page ne peut être garantie. Toutes ces instructions seront considérées comme des souhaits qui seront réalisés dans la mesure du possible. En aucun cas le non-respect de ces souhaits ne peut ouvrir le droit à une indemnité quelconque ou à un prix réduit.
              </li>
              <li>
                <b>4.3.</b>
                {' '}
                Pour des raisons techniques, il n’est en général pas possible de faire paraître une annonce dans un seul média. Lorsqu’un seul tarif est prévu pour parution dans plusieurs médias, ce tarif n’est pas divisible et la totalité du prix est donc exigible même si un seul média est demandé.
              </li>
            </ul>
            <li><b>5. MATERIEL</b></li>
            <ul>
              <li>
                <b>5.1.</b>
                {' '}
                Le matériel à utiliser doit être transmis en temps utile et doit être conforme aux spéciﬁcations techniques.
              </li>
              <li>
                <b>5.2</b>
                {' '}
                Dans le cas où le matériel livré n’est pas techniquement utilisable, L’Avenir est en droit de palier aux déﬁciences sans y être obligé. Les frais y afférent seront facturés au donneur d’ordre. En aucun cas les erreurs qui résultent d’un matériel non-conforme ou d’une livraison tardive de matériel ne peuvent donner lieu à une réduction de prix, ni à une réinsertion, ni à des dommages et intérêts sous quelque forme que ce soit.
              </li>
              <li>
                <b>5.3.</b>
                {' '}
                Les ordres d’insertion devant en principe être transmis par écrit ou de manière électronique, L’Avenir ne peut être tenue pour responsable des erreurs de réalisation lorsque la commande a été transmise verbalement, par téléphone ou par fax.
              </li>
              <li>
                <b>5.4.</b>
                {' '}
                L’Avenir ne peut être tenue pour responsable des dommages éventuels occasionnés au matériel fourni par l’annonceur pendant leur traitement.
              </li>
              <li>
                <b>5.5.</b>
                {' '}
                Le matériel est tenu à la disposition de l’annonceur pendant au maximum un mois suivant l’insertion, délai après lequel il est détruit.
              </li>
              <li>
                <b>5.6.</b>
                {' '}
                Dans le cas où le donneur d’ordre conﬁe à L’Avenir la mise en page de matériel sans directives précises, L’Avenir ne peut être tenue pour responsable de la façon dont la mise en page a été conçue.
              </li>
            </ul>
            <li>
              <b>6. TRADUCTIONS.</b>
              {' '}
                L’Avenir se réserve le droit de refuser les annonces rédigées dans une langue autre que celle du média.
            </li>
            <li>
              <b>7. ANNULATION.</b>
              {' '}
                Le donneur d’ordre ne peut pas annuler ou interrompre une commande ou un ordre en cours; la commande sera facturée dans son intégralité.
            </li>
            <li>
              <b>8. TARIFS.</b>
              {' '}
                Les annonces sont calculées suivant le tarif en vigueur au moment de l’insertion. L’Avenir se réserve le droit de modiﬁer à tout instant le tarif et/ou le format d’insertion, comme publié sur les sites web de L’Avenir.
            </li>
            <li><b>9. CONDITIONS DE PAIEMENT</b></li>
            <ul>
              <li>
                <b>9.1.</b>
                {' '}
                Les factures sont payables sur le compte bancaire ou au siège de L’Avenir. Les taxes et impôts ainsi que tous les frais de transaction éventuels sont à charge du donneur d’ordre. Les lettres de change ne sont pas acceptées.
              </li>
              <li>
                <b>9.2.</b>
                {' '}
                L’Avenir se réserve toujours le droit d’exiger le paiement préalable des factures. Sauf disposition contraire, les factures sont payables à 15 jours de la date de facture. A défaut de paiement endéans ce délai, L’Avenir est en droit de suspendre les annonces en cours, sans préavis, sous réserve de ses droits et sans être redevable de quelconques indemnités.
              </li>
              <li>
                <b>9.3.</b>
                {' '}
                Toute facture impayée à l’échéance sera majorée, de plein droit et sans qu’une mise en demeure soit nécessaire, de l’intérêt légal sur le montant de la facture, calculé à partir de la date de facture, ainsi que d’une indemnité forfaitaire de 15% du montant total de la facture avec un minimum de 50 €.
              </li>
              <li>
                <b>9.4.</b>
                {' '}
                Lorsque le donneur d’ordre a demandé d’établir la facture au nom d’un tiers et que L’Avenir a accepté de le faire, L’Avenir reste néanmoins en droit d’exiger paiement auprès du donneur d’ordre d’origine dès que la facture n’est pas payée par le tiers à la date d’échéance. Les mêmes règles valent si L’Avenir a accepté une demande de note de crédit avec facturation à un tiers.
              </li>
            </ul>
            <li><b>10. RECLAMATIONS</b></li>
            <ul>
              <li>
                <b>10.1.</b>
                {' '}
                Les réclamations doivent être transmises par écrit dans les cinq jours ouvrables qui suivent la publication.
              </li>
              <li>
                <b>10.2.</b>
                {' '}
                Toute réclamation relative à la facturation même doit être introduite par écrit dans les 14 jours à dater de la date de facture, avec mention du numéro de facture.
              </li>
              <li>
                <b>10.3.</b>
                {' '}
                L’Avenir n’est jamais responsable pour les circonstances indépendantes de sa volonté qui ont comme conséquence que l’exécution de la commande soit reportée ou rendue impossible telles que grèves, inondations, incendies, problèmes d’impression ou de distribution et autres cas de force majeure.
              </li>
              <li>
                <b>10.4.</b>
                {' '}
                Les erreurs, fautes d’impression, reproduction mauvaise ou vague du texte et/ou d’images, un tirage ou une audience moins grande que d’habitude, ne peuvent donner lieu à des dommages et intérêts ni à des réductions tarifaires sauf si le donneur d’ordre démontre une faute lourde ou intentionnelle dans le chef de L’Avenir.
              </li>
              <li>
                <b>10.5.</b>
                {' '}
                Nonobstant ce qui a été précisé ci-avant, L’Avenir ne sera jamais redevable de dommages- intérêts plus élevés que le prix que L’Avenir aurait pu mettre en compte pour l’ordre, excepté dans le cas où la preuve d’une faute volontaire est établie.
              </li>
            </ul>
            <li><b>11. DOMICILIATION DES ANNONCES</b></li>
            <ul>
              <li>
                <b>11.1.</b>
                {' '}
                Si le donneur d’ordre d’une annonce, pour laquelle des réponses sont possibles, veut garder l’anonymat, il peut faire adresser les réponses à l’annonce dont il a commandé l’insertion à l’adresse du medium. Dans ce cas, les réponses seront tenues à sa disposition pendant un délai de 15 jours à dater de la publication de la dernière insertion. S’il demande de les lui envoyer, ceci sera fait à ses risques et périls et les frais seront à sa charge. Passé ce délai, L’Avenir est en droit de détruire ce courrier. Les imprimés publicitaires et les publipostages ne sont pas réexpédiés.
              </li>
            </ul>
            <li><b>12. TRIBUNAL COMPETENT ET DROIT APPLICABLE</b></li>
            <ul>
              <li>
                <b>12.1.</b>
                {' '}
                Le droit belge est toujours applicable à l’exception des lois internationales qui amèneraient à l’application d’un autre système juridique.
              </li>
              <li>
                <b>12.2.</b>
                {' '}
                Pour tout litige dans lequel la S.A. L’Avenir Advertising  et/ou la S.A. Les Editions de L’Avenir est demandeur ou défendeur, les tribunaux de l’arrondissement judiciaire de Marche-en-Famenne ont compétence exclusive. Pour toutes les autres sociètés, c’est la justice de Paix de l’arrondissement du siège social de la société qui sera compétent.
              </li>
            </ul>
            <li>
              <b>13. CLAUSE.</b>
              {' '}
                Le client [l’agence-média/l’annonceur/l’agence de publicité] s’interdit toute exploitation et, de manière plus générale, tout traitement, direct ou indirect, immédiat ou différé, de données individualisables - à savoir de données identiﬁant ou permettant l’identiﬁcation d’une personne physique - qui ne serait pas strictement limité à une diffusion efﬁcace de la publicité placées par lui sur le(s) site(s) web [de l’éditeur/du fournisseur]. A défaut, [l’éditeur/le fournisseur] se réserve le droit de mettre ﬁn à la commande complète, sans préjudice de son droit au paiement complet de ladite commande.
            </li>
            <li>Conditions spécifiques concernant l’offre de bons de valeur Si le donneur d’ordre conﬁe à L’Avenir la vente de bons de valeur (intitulé également ‘voucher’), les conditions suivantes seront applicables, nonobstant les obligations qui découlent de la loi, telle que le respect du droit de rétractation dans les cas spéciﬁés par la loi. Le donneur d’ordre octroie un mandat au système de payement online que L’Avenir peut désigner</li>
            <ul>
              <li>
                <b>(1)</b>
                {' '}
                pour recevoir le prix du produit à livrer ou le service à prester par le donneur d’ordre en son nom et pour son compte
              </li>
              <li>
                <b>(2)</b>
                {' '}
                d’envoyer le bon de valeur au consommateur après réception du payement
              </li>
              <li>
                <b>(3)</b>
                {' '}
                quand le consommateur exerce son droit de rétractation, de lui rembourser le prix.
              </li>
            </ul>
            <li>Le donneur d’ordre s’engage à transmettre le produit convenu ou de prester le service convenu à ou envers tous les détenteurs d’un bon de valeur, conformément à la description sur le bon de valeur, aux usages honnêtes et conformément tout ce qui peut raisonnablement être attendu, même si cela n’a pas été mentionné expressément. Le donneur d’ordre garantit L’Avenir et le système de payement online contre toutes actions possibles auxquelles l’émission et l’usage du bon de valeur, du service rendu ou du bien vendu pourraient donner lieu. Le donneur d’ordre peut choisir de limiter le nombre de bons de valeur qui peuvent être mis en vente; en ce cas L’Avenir peut mentionner le nombre disponible. Le système de payement online attachera à chaque bon de valeur un code unique. Il appartient à la responsabilité du donneur d’ordre de contrôler ce code et de s’assurer que ce bon de commande ne peut être utilisé qu’une seule fois. En contrepartie des prestations de L’Avenir et le système de payement online, le donneur d’ordre est redevable du payement</li>
            <ul>
              <li>
                <b>(1)</b>
                {' '}
                de frais de mise en page à facturer par L’Avenir; à défaut d’une spéciﬁcation sur le bon de commande ces frais seront 50 € hors TVA
              </li>
              <li>
                <b>(2)</b>
                {' '}
                d’une commission à facturer par le système de payement online. A défaut d’une spéciﬁcation sur le bon de commande, cette commission s’élève à 25% (hors TVA) du montant reçu pour le bon de valeur par le système de payement online pour compte du donneur d’ordre.
              </li>
            </ul>
            <li>Le système de payement online transmettra les montants reçus concernant les bons de valeur ainsi spéciﬁés – moins la contrevaleur des factures pour la commission - après que le donneur d’ordre aura fourni la preuve que le produit ou le service auquel le bon de valeur a trait, a effectivement été livrée et jamais avant que le consommateur n’ait eu l’occasion d’exercer son droit de rétractation. Le donneur d’ordre doit remplir le code unique que chaque bon de commande reçu par lui sur une page web mise à sa disposition à cette ﬁn par L’Avenir ou renvoyer les bons de valeur par pli recommandé à l’adresse indiqué par L’Avenir accompagné d’un détail précisant des codes uniques des bons de valeur. Ces conditions générales sont d’application dès le 1er janvier 2015.</li>
          </ul>
        </div>
        <p>L’Avenir Advertising S.A. - Route de Hannut 38 - 5004 Bouge - webshop@lavenir.net</p>
        <span>Tarifs, formats, fréquence valables jusqu’au 31 décembre 2019. A partir de cette date, adaptations possibles.</span>
      </div>
    </Modal>
    ));
};

CGV.defaultProps = {
  display: false,
};


const mapStateToProps = state => ({
  device: state.device,
});

export default connect(mapStateToProps)(CGV);
