/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import fetch from 'isomorphic-fetch';
import { withRouter } from 'react-router';
import TextButton from 'components/TextButton';
import LoadingBox from 'components/LoadingBox';
import Layout from '../../components/Layout';

import '../SignUp/style.scss';


const DownloadMaterial = (props) => {
  const [showButton, setShowButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState('');

  const handleDownload = async () => {
    const { order_id } = props.match.params;
    setLoading(true);
    setShowButton(false);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/order/${order_id}/material/download`);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'mat.zip';
      document.body.appendChild(a);
      a.click();
      a.remove();
      setDownloadSuccess(true);
      setLoading(false);
    } catch (error) {
      setDownloadError(true);
      setDownloadErrorMessage(error.message);
    }
  };

  const hideLoader = () => {
    setShowButton(true);
    setDownloadSuccess(false);
    setDownloadError(false);
    setDownloadErrorMessage('');
  };

  return (
    <Layout
      cover="/assets/images/covers/reservation.jpg"
      isHome
    >
      <div className="signUp-container">
        <div className="signUp-container--header">Télécharger votre matériel</div>

        {showButton ? <TextButton text="Télécharger" handleClick={handleDownload} /> : loading ? (
          <LoadingBox
            loading={loading}
            success={downloadSuccess}
            downloadSuccessMessage
            error={downloadError}
            showErrorButton
            handleClick={hideLoader}
            errorMessage={downloadErrorMessage}
          />
        ) : <TextButton text="Retour" handleClick={() => props.history.push('/personalPage')} />}
      </div>
    </Layout>
  );
};

export default withRouter(DownloadMaterial);
