/* eslint-disable react/prop-types */
import React from 'react';

import ProximagCheckBox from '../ProximagCheckBox';

import './style.scss';

class FormatSelection extends React.Component {
  handleFormatSelect() {
    const { onSelect } = this.props;
    onSelect();
  }

  render() {
    const { displayText, image, selected } = this.props;
    return (
      <div className="formatSelection-container">
        <div
          className={`formatSelection-content ${selected ? 'selected' : null}`}
          onClick={() => this.handleFormatSelect()}
          onKeyPress={() => this.handleFormatSelect()}
          tabIndex="0"
          role="button"
        >
          <div className="formatSelection-image">
            <img src={image} alt="format" />
          </div>
          <div className="formatSelection-footer">
            <div className="footer-checkbox">
              <ProximagCheckBox
                checked={selected}
                text={displayText}
                centerText
                handleCheck={() => this.handleFormatSelect()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormatSelection;
