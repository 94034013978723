import * as types from '../actions/actionTypes';

const defaultState = {
  savingOrder: false,
  edition: undefined,
  region: undefined,
  selectedEditionDates: [],
  formatType: undefined,
  format: undefined,
  materialType: undefined,
  materialFiles: [],
  price: undefined,
  error: false,
  errorMessage: undefined,
  generalConditionsAccepted: false,
  invoiceRequested: true,
  orderData: {
    editionId: undefined,
    editionFormatPriceId: undefined,
    publicationDateIds: [],
    formatPrice: undefined,
    advertising: {
      advertisingTypeCode: 'TBD',
      mail: undefined,
      activityName: undefined,
      activityDescription: undefined,
      phoneNumber: undefined,
      websiteLink: undefined,
      facebookLink: undefined,
      openingHours: undefined,
      address: {
        street: undefined,
        number: undefined,
        municipalityId: undefined,
      },
    },
  },
  savedOrder: false,
};

const currentOrder = (state = defaultState, action) => {
  const {
    edition,
    region,
    selectedEditionDates,
    format,
    materialType,
    materialFiles,
    invoiceRequested,
    generalConditionsAccepted,
    editionId,
    publicationDateIds,
    editionFormatPriceId,
    formatPrice,
    fromPrice,
    error,
  } = action;
  switch (action.type) {
    case types.SET_ORDER_DATA_EDITION:
      return { ...state, orderData: { ...state.orderData, editionId } };
    case types.SET_EDITION:
      return { ...state, edition };
    case types.SET_FROM_PRICE:
      return { ...state, fromPrice };
    case types.SET_REGION:
      return { ...state, region };
    case types.SET_SELECTED_EDITION_DATES:
      return {
        ...state,
        selectedEditionDates,
      };
    case types.SET_ORDER_DATA_EDITION_DATES:
      return {
        ...state,
        orderData: { ...state.orderData, publicationDateIds },
      };
    case types.SET_ORDER_ERROR: // fall through
    case types.CLEAR_ORDER_ERROR:
      return { ...state, ...error };
    case types.SET_FORMAT:
      return { ...state, format };
    case types.SET_ORDER_DATA_FORMAT:
      return {
        ...state,
        orderData: { ...state.orderData, editionFormatPriceId },
      };
    case types.SET_FORMAT_PRICE:
      return {
        ...state,
        orderData: { ...state.orderData, formatPrice },
      };
    case types.SET_MATERIAL_TYPE:
      return {
        ...state,
        materialType,
        orderData: { ...state.orderData, advertising: { ...materialType } },
      };
    case types.SET_MATERIAL_FILES:
      return { ...state, materialFiles };
    case types.SET_INVOICE_DEMAND:
      return { ...state, invoiceRequested };
    case types.SET_GENERAL_CONDITIONS_ACCEPTED:
      return { ...state, generalConditionsAccepted };
    case types.ADD_ORDER_REQUEST:
      return { ...state, savingOrder: true };
    case types.ADD_ORDER_SUCCESS:
      return { ...state, savingOrder: false, savedOrder: { ...action.payload.order } };
    case types.SET_SAVED_ORDER:
      return { ...state, savedOrder: action.payload.savedOrder ? { ...action.payload.savedOrder } : false };
    case types.ADD_ORDER_FAILURE:
      return { ...state, savingOrder: false };
    case types.RESET_ORDER_REDUCER:
      return { ...defaultState };
    default:
      return state;
  }
};

export default currentOrder;
