/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key, no-nested-ternary */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import * as currentOrderActions from '../../actions/currentOrderActions';

import TextButton from '../../components/TextButton';

import './style.scss';

class OrderRecap extends React.Component {
  state = {
    order: false,
  };

  componentWillMount() {
    const { currentOrder } = this.props;
    const {
      savedOrder,
    } = currentOrder;

    if (savedOrder !== undefined) {
      this.setState({
        order: savedOrder,
      });
    }
  }

  clickNextHandler = async (path, nextPage, routeData) => {
    const { setCurrentOrderError } = this.props.currentOrderActions;
    const { history, beforeClickHandler } = this.props;

    if (this.checkForOrderErrors()) return;
    try {
      if (beforeClickHandler !== undefined) {
        const bchResp = await beforeClickHandler();
        // Prevent redirect
        if (bchResp === 'no_redirect') {
          return;
        }
      }
      history.push(path, { nextPage, ...routeData });
    } catch (error) {
      setCurrentOrderError({ error: true, errorMessage: error.message ? error.message : error });
    }
  }


  checkForOrderErrors() {
    let errorMessage; let
      error;
    const { location, currentOrderActions, currentOrder } = this.props; // eslint-disable-line no-shadow
    const { setCurrentOrderError } = currentOrderActions;
    const {
      edition,
      selectedEditionDates,
      format,
      materialType,
      materialFiles,
      generalConditionsAccepted,
    } = currentOrder;

    // Different error types
    const editionsError = !edition || selectedEditionDates.length === 0;
    const formatError = !format;
    const uploadError = !materialType
      || (materialType.type === 'existing' && materialFiles.length === 0);
    const orderError = !generalConditionsAccepted;

    // Different error messages
    const editionsErrorMessage = "Choisissez une édition ainsi qu'une ou plusieurs dates de parution";
    const formatErrorMessage = 'Choisissez un format de parution';
    const uploadErrorMessage = 'Choisissez une solution et le matériel nécessaire à votre publication';
    const orderErrorMessage = "Merci d'accepter les conditions générales de vente";

    // Switch case based on location
    switch (location.pathname) {
      case '/editions':
        if (editionsError) errorMessage = editionsErrorMessage;
        error = editionsError;
        break;
      case '/format':
        if (formatError) errorMessage = formatErrorMessage;
        error = formatError;
        break;
      case '/upload':
        if (uploadError) errorMessage = uploadErrorMessage;
        error = uploadError;
        break;
      case '/orderRecap':
        if (orderError) errorMessage = orderErrorMessage;
        else if (editionsError) errorMessage = editionsErrorMessage;
        else if (uploadError) errorMessage = uploadErrorMessage;
        else if (formatError) errorMessage = formatErrorMessage;
        error = editionsError || formatError || uploadError || orderError;
        break;
      default:
        break;
    }

    // Check for error then return
    if (error) setCurrentOrderError({ error, errorMessage });
    return error;
  }

  clickPreviousHandler(path) {
    const { history } = this.props;
    history.push(path);
  }

  // Check for fields that are considered required before ordering
  checkMissingInfo() {
    const { user, currentOrder } = this.props;
    const { invoiceRequested } = currentOrder;
    // 'companyVAT', is removed from validation for now
    const missingCompanyInfo = ['companyName', 'companyType'].filter(
      i => !user[i],
    );

    const missingLocationInfo = [
      'street',
      'number',
      'municipalityId',
    ].filter(i => !user.address[i]);

    const missingUserInfo = ['firstName', 'lastName', 'mail'].filter(i => !user[i]);

    return invoiceRequested
      ? [...missingUserInfo, ...missingLocationInfo, ...missingCompanyInfo]
      : [...missingUserInfo, ...missingLocationInfo];
  }

  computePrice(fromSaved = false, ttc = false) {
    const { order } = this.state;
    const { currentOrder, from } = this.props;
    const {
      selectedEditionDates,
      fromPrice,
      orderData,
    } = currentOrder;
    // If ttc get infos from order, else from currentOrder
    let price;
    let numberOfDates;
    if (order && fromSaved) {
      if (ttc) {
        return order.totalPrice;
      }
      price = order.editionFormatPrice.price;
      numberOfDates = order.orderPulicationDates.length;
    } else {
      price = from ? fromPrice : orderData.formatPrice;
      numberOfDates = selectedEditionDates.length;
    }
    if (price) {
      if (orderData.formatPrice !== undefined) {
        return numberOfDates > 0 ? orderData.formatPrice * numberOfDates : orderData.formatPrice;
      }
      return numberOfDates > 0 ? price * numberOfDates : price;
    }
    return '';
  }

  renderButtons() {
    const { location, user } = this.props;
    let path;

    switch (location.pathname) {
      case '/editions':
        return [
          {
            text: 'Choisir le format',
            clickHandler: () => this.clickNextHandler('/format'),
          },
        ];
      case '/format':
        path = user.isConnected
          ? ['/upload']
          : ['/connection', '/upload'];
        return [
          {
            text: 'Retour',
            clickHandler: () => {
              this.clickPreviousHandler('/editions');
            },
            color: 'pink',
          },
          {
            text: 'Matériel',
            clickHandler: () => this.clickNextHandler(...path),
          },
        ];

      case '/upload':
        path = user.isConnected
          ? ['/orderRecap']
          : ['/connection', '/orderRecap'];
        return [
          {
            text: 'Retour',
            clickHandler: () => this.clickPreviousHandler('/format'),
            color: 'pink',
          },
          {
            text: 'Récapitulatif',
            clickHandler: () => this.clickNextHandler(...path),
          },
        ];
      case '/orderRecap':
        const missingFields = this.checkMissingInfo(); // eslint-disable-line no-case-declarations

        path = missingFields.length
          ? ['/personalInfo?title=missing_fields', '/payment/credit', { missingFields }]
          : ['/payment/credit'];
        return [
          {
            text: 'Retour',
            color: 'pink',
            clickHandler: () => this.clickPreviousHandler('/upload'),
          },
          {
            text: 'Publier',
            clickHandler: () => this.clickNextHandler(...path),
          },
        ];
      case '/payment/credit':
        return [
          {
            text: 'Payer',
            clickHandler: () => this.clickNextHandler('/personalPage?message=payment_success'),
          },
        ];
      case '/payment/debit':
        return [
          {
            text: 'Payer',
            clickHandler: () => this.clickNextHandler('/personalPage?message=payment_success'),
          },
        ];

      default:
        return [];
    }
  }

  render() {
    const { currentOrder, from, location } = this.props;
    let {
      edition,
      selectedEditionDates,
      format,
      materialType,
      materialFiles,
    } = currentOrder;
    const {
      savedOrder,
      error,
      errorMessage,
    } = currentOrder;

    if (savedOrder) {
      const compo = savedOrder.advertising.advertisingType.code === 'RTU' ? 'existing' : 'composition';
      edition = savedOrder.editionFormatPrice.editionName;
      selectedEditionDates = savedOrder.orderPulicationDates.map(d => d.publicationDate.dateTimeStamp);
      format = { description: `${savedOrder.editionFormatPrice.format.width}mm x ${savedOrder.editionFormatPrice.format.height}mm (${savedOrder.editionFormatPrice.format.value})` };
      materialType = {
        type: compo,
        description: compo === 'composition' ? 'Composition' : 'Matériel fini',
        code: savedOrder.advertising.advertisingType.code,
      };
      materialFiles = compo === 'composition' ? savedOrder.advertising.materialsLinked : [savedOrder.advertising.advertisingFinalFile];
    }

    const buttons = this.renderButtons();

    return (
      <div className="currentOrder">
        <div className="currentOrder-content">
          {/* Title */}
          <div className="currentOrder-title">
            <div className="currentOrder-title-content">
              <div>Votre commande</div>
            </div>
          </div>

          {/* Edition */}
          <div className="currentOrder-edition">
            <div className="currentOrder-edition-content">
              <div className="currentOrder-edition-content-label">Edition&nbsp;:</div>
              <div className="currentOrder-edition-content-edition">
                {edition ? edition : '—' /* eslint-disable-line no-unneeded-ternary */}
              </div>
            </div>
          </div>

          {/* Dates */}
          <div className="currentOrder-dates">
            <div className="currentOrder-dates-content">
              <div className="currentOrder-dates-content-label">Dates&nbsp;:</div>
              <div className="currentOrder-dates-content-dates">
                {selectedEditionDates.length > 0
                  ? selectedEditionDates.map((date, i) => (
                    <div key={i}>
                      {moment.unix(date).format('dddd D MMMM YYYY')}
                    </div>
                  ))
                  : '—'}
              </div>
            </div>
          </div>

          {/* Format */}
          <div className="currentOrder-format">
            <div className="currentOrder-format-content">
              <div className="currentOrder-format-content-label">Format&nbsp;:</div>
              <div className="currentOrder-format-content-format">
                <div>{format ? format.description : '—'}</div>
              </div>
            </div>
          </div>

          {/* Matériel */}
          <div className="currentOrder-format">
            <div className="currentOrder-format-content">
              <div className="currentOrder-format-content-label">Matériel&nbsp;:</div>
              <div className="currentOrder-format-content-format">
                <div>{materialType ? materialType.description : '—'}</div>
                <div>
                  {materialType && materialFiles
                    ? materialFiles.length === 1 ? `${materialFiles.length} fichier` : `${materialFiles.length} fichiers`
                    : ' '}
                </div>
              </div>
            </div>
          </div>

          {/* Price */}
          <div className="currentOrder-price">
            <div className="currentOrder-price-content">
              <div className="currentOrder-format-price-label">
                <div>
                  {from
                    ? 'A Partir de : '
                    : 'Prix Total HTaxes :'}
                </div>
              </div>
              <div className="currentOrder-format-price-price">
                <div>
                  {this.computePrice(location.pathname.match(/\/payment\/.*/))}
                  {' €'}
                </div>
              </div>
            </div>
          </div>
          { location.pathname.match(/\/payment\/.*/) ? (
            <div className="currentOrder-price currentOrder-price--ttc">
              <div className="currentOrder-price-content">
                <div className="currentOrder-format-price-label">
                  <div>
Prix Total
                    {' '}
                    <abbr title="3,1% écotaxe et 21% TVA">TTC*</abbr>
:
                  </div>
                </div>
                <div className="currentOrder-format-price-price">
                  <div>
                    {this.computePrice(true, true)}
                    {' €'}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Button */}
          <div className="currentOrder-button">
            <div
              className={`currentOrder-button-content ${
                buttons.length ? 'multi' : null
              }`}
            >
              {buttons.map((button, i) => (
                <div className="button-container" key={i}>
                  {i > 0 ? <div className="spacer" /> : null}
                  <div className="button">
                    <TextButton
                      fitToContainer
                      text={button.text}
                      handleClick={() => button.clickHandler()}
                      color={button.color}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Error */}
        <div className="currentOrder-error">
          <div className="currentOrder-error-content">
            {error ? (
              <div className="currentOrder-error-content-message">
                {errorMessage}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrderRecap),
);
