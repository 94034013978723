/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import {
  FaFacebookSquare,
  FaLinkedin,
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import CGV from '../CGV';
import PrivetConditions from '../PrivetConditions';
import Cookies from '../Cookies';

import s from './style.module.scss';
import './resetModal.scss';

class Footer extends React.Component {
  state={
    displayCGV: false,
    displayPrivate: false,
    displayCookies: false,
  }

  showModal(params) {
    const { displayCGV, displayPrivate, displayCookies } = this.state;
    switch (params) {
      case 'cgv':
        this.setState({
          displayCGV: !displayCGV,
        });
        break;
      case 'private':
        this.setState({
          displayPrivate: !displayPrivate,
        });
        break;
      case 'cookies':
        this.setState({
          displayCookies: !displayCookies,
        });
        break;
      default:
        this.setState({
          displayCGV: false,
          displayPrivate: false,
          displayCookies: false,
        });
    }
  }

  render() {
    const { displayCGV, displayPrivate, displayCookies } = this.state;

    return (
      <>
        <footer className={s.container}>
          <div className={s.container__isTop}>
            <div className={s.container__isTop__item}>
              <div className={s.container__isTop__item__img}>
                <a href="https://advertising.lavenir.net/#nous-contacter-2" rel="noopener noreferrer" target="blank"><img src="/assets/images/logo-white.svg" alt="Avenir Advertising" /></a>
              </div>
            </div>
            <div className={s.container__isTop__item}>
              <h6>Newsletter</h6>
              <p>Inscrivez-vous à notre newsletter pour recevoir toutes nos offres et bons plans</p>
              <data id="mj-w-res-data" data-token="a301344c13017b67ea3b4aca3d2d8abf" className="mj-w-data" data-apikey="2RcA" data-w-id="ipl" data-lang="fr_FR" data-base="https://app.mailjet.com" data-width="640" data-height="328" data-statics="statics" />
              <div
                className="mj-w-button mj-w-btn"
                data-token="a301344c13017b67ea3b4aca3d2d8abf"
                style={{
                  color: 'white', padding: '0 15px', backgroundColor: 'white', textAlign: 'center', verticalAlign: 'middle', display: 'inline-block', borderRadius: '3px', marginRight: '15px',
                }}
              >
                <div style={{ display: 'table', height: '45px' }}>
                  <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                    <div
                      className="mj-w-button-content"
                      style={{
                        display: 'inline-block', textAlign: 'center', fontSize: '0.8rem', verticalAlign: 'middle', color: '#0b353d', textTransform: 'uppercase',
                      }}
                    >
                      <b>Abonnez-vous à la newsletter&nbsp;!</b>
                    </div>
                  </div>
                </div>
              </div>
              <h6>Rejoignez-nous</h6>
              <ul>
                <li><a href="https://www.facebook.com/Laveniradvertising/" target="blank"><FaFacebookSquare /></a></li>
                <li><a href="https://www.linkedin.com/company/lavenir-advertising/" target="blank"><FaLinkedin /></a></li>
              </ul>
            </div>
            <div className={s.container__isTop__item}>
              <h6>Nos médias</h6>
              <ul>
                <li><a href="https://www.lavenir.net/" target="blank">Lavenir.net</a></li>
                <li><a href="http://proximagservices.lavenir.net/" target="blank">Proximag</a></li>
                <li><a href="https://www.moustique.be/" target="blank">Moustique</a></li>
                <li><a href="https://www.telepocket.be/" target="blank">TéléPocket</a></li>
                <li><a href="https://www.lejde.be/" target="blank">JDE</a></li>
              </ul>
            </div>
            <div className={s.container__isTop__item}>
              <h6><NavLink to="/contact">Contact</NavLink></h6>
              <ul>
                <li><a href="mailto:webshop@lavenir.net">webshop@lavenir.net</a></li>
                <li><a href="tel:081248802">081/24.88.02</a></li>
              </ul>
            </div>
          </div>
          <div className={s.container__isBottom}>
            <p>
              {`@ ${new Date().getFullYear()} Avenir Advertising`}
            </p>
            <ul>
              <li
                onClick={() => this.showModal('private')}
                onKeyPress={() => this.showModal('private')}
                role="presentation"
              >
                <span>Vie privée</span>
              </li>
              <li
                onClick={() => this.showModal('cookies')}
                onKeyPress={() => this.showModal('cookies')}
                role="presentation"
              >
                <span>Cookies</span>
              </li>
              <li
                onClick={() => this.showModal('cgv')}
                onKeyPress={() => this.showModal('cgv')}
                role="presentation"
              >
                <span>Conditions générales de vente</span>
              </li>
            </ul>
          </div>
        </footer>
        <CGV display={displayCGV} onClickOutside={() => this.showModal('cgv')} />
        <PrivetConditions display={displayPrivate} onClickOutside={() => this.showModal('private')} />
        <Cookies display={displayCookies} onClickOutside={() => this.showModal('cookies')} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  device: state.device,
});

export default connect(mapStateToProps)(Footer);
