/* eslint-disable react/prop-types */
import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { withRouter } from 'react-router-dom';

import './style.scss';

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  handleNavigation(destination) {
    const { location, history } = this.props;
    this.closeMenu();
    if (location.pathname !== destination) {
      history.push(destination);
    }
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }

  render() {
    const { menuOpen } = this.state;
    return (
      <Menu
        isOpen={menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        <div
          className="menu-item"
          onClick={() => this.handleNavigation('/')}
          onKeyPress={() => this.handleNavigation('/')}
          tabIndex="0"
          role="button"
        >
          Home
        </div>
        <div
          className="menu-item"
          onClick={() => this.handleNavigation('/editions')}
          onKeyPress={() => this.handleNavigation('/editions')}
          tabIndex="0"
          role="button"
        >
          Réserver
        </div>
        <div
          className="menu-item"
          onClick={() => this.handleNavigation('/faq')}
          onKeyPress={() => this.handleNavigation('/faq')}
          tabIndex="0"
          role="button"
        >
          Faq
        </div>
        <div
          className="menu-item"
          onClick={() => this.handleNavigation('/contact')}
          onKeyPress={() => this.handleNavigation('/contact')}
          tabIndex="0"
          role="button"
        >
          Contact
        </div>
      </Menu>
    );
  }
}

export default withRouter(SideBar);
