/* eslint-disable react/prop-types */
import React from 'react';

import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import TextButton from '../TextButton';
import RenderField from '../RenderField';

import { validate } from '../../helpers/validate';
import s from './style.module.scss';

// eslint-disable-next-line import/no-mutable-exports
const LoginForm = (props) => {
  const {
    handleSubmit, hidden,
  } = props;

  const handleSignUp = () => {
    const { history } = props;
    history.push('/inscription');
  };

  return (
    <>
      <form className={hidden ? 'form-hidden' : ''} onSubmit={handleSubmit}>
        <Field
          name="mail"
          type="mail"
          component={RenderField}
          placeholder="jeandupont@gmail.com"
          label="E-mail"
        />
        <Field
          name="password"
          type="password"
          component={RenderField}
          placeholder="Mot de passe"
          label="Mot de passe"
        />
        <div className="signUp_reset"><Link to="/user/forgetPassword">Mot de passe oublié ?</Link></div>
        <div className="signUp-buttons--row">
          <TextButton
            text="Se connecter"
            type="submit"
            handleClick={handleSubmit}
            style={{ width: '70%' }}
          />
        </div>
      </form>
      <div className={s.container}>
          Vous n&apos;êtes pas encore inscrit ?
        <div
          onClick={handleSignUp}
          onKeyPress={handleSignUp}
          role="button"
          tabIndex="0"
          className={s.container__button}
        >
          Inscrivez-vous !
        </div>
      </div>
    </>
  );
};


const ConnectedForm = withRouter(
  reduxForm({
    form: 'login',
    validate,
  })(LoginForm),
);

export default connect(state => ({
  initialValues: state.user,
}))(ConnectedForm);
