/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BasketIndicator from '../../components/BasketIndicator';
import SideBar from '../../components/SideBar';
import ConnectionIndicator from '../../components/ConnectionIndicator';

import './headerMobile.scss';

class HeaderMobile extends React.Component {
  handleClick(destination, nextPage) {
    const { location, history } = this.props;
    if (location.pathname !== destination) {
      history.push(destination, { nextPage });
    }
  }

  render() {
    return (
      <>
        <div className="header-sideBar-container">
          <SideBar />
        </div>
        <div className="header-container">
          <div className="header-content">
            <div className="header-empty-container" />
            <div
              className="header-logo-container"
              onClick={() => this.handleClick('/')}
              onKeyPress={() => this.handleClick('/')}
              role="button"
              tabIndex="0"
            >
              <img src="/assets/images/logo.svg" alt="logo" />
            </div>
            <div className="header-basket-container">
              <div className="connection">
                <ConnectionIndicator />
              </div>
              <div className="basket">
                <BasketIndicator id="basket" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(HeaderMobile));
