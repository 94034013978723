/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { urlParam } from 'helpers/url';
import LoginForm from '../../components/LoginForm';
import LoadingBox from '../../components/LoadingBox';
import Layout from '../../components/Layout';

import '../SignUp/style.scss';

import * as userService from '../../services/user';
import * as userActions from '../../actions/userActions';
import TextButton from '../../components/TextButton';


const Connexion = (props) => {
  const [loading, setLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState(
    'Erreur durant la connexion',
  );
  const [showForm, setShowForm] = useState(true);
  const [showValidationMessage, setFlagValidationMessage] = useState(false);
  const [validationMessage] = useState('Compte activé!');

  const onFormSubmit = async (values) => {
    // eslint-disable-next-line no-shadow
    const {
      history, location, userService, userActions,
    } = props;

    let nextPage;
    // eslint-disable-next-line prefer-destructuring
    if (location.state) nextPage = location.state.nextPage;

    setLoading(true);
    setShowForm(false);
    try {
      await userService.loginUser(values);
      setLoading(false);
      setLoginSuccess(true);
      if (nextPage) {
        history.push(nextPage);
      } else {
        history.push('/personalPage');
      }
    } catch (err) {
      setLoading(false);
      setLoginError(true);
      if (err.message === "Le compte de l'utilisateur n'est pas encore activé.") {
        setLoginErrorMessage(
          <>
            <p>{err.message}</p>
            <TextButton
              text="Activer votre compte"
              handleClick={() => {
                userActions.setEmail(values.mail);
                history.push('/user/validate');
              }}
            />
          </>,
        );
      } else {
        setLoginErrorMessage(err.message);
      }
    }
  };

  const hideLoader = () => {
    setShowForm(true);
    setLoginSuccess(false);
    setLoginError(false);
    setLoginErrorMessage('');
  };

  useEffect(() => {
    const validationMsg = urlParam('validationMsg');
    if (validationMsg !== null && validationMsg === 'success') {
      setFlagValidationMessage(true);
    }
  }, [showValidationMessage]);

  return (
    <Layout
      cover="/assets/images/covers/reservation.jpg"
      isHome
    >
      <div className="signUp-container">
        {showValidationMessage && (
          <span className="signUp-container--message">
            {validationMessage}
          </span>
        )}
        <div className="signUp-container--header">Connexion</div>
        <LoginForm hidden={!showForm} onSubmit={onFormSubmit} />
        {!showForm && (
          <div className="loading-container">
            <LoadingBox
              loading={loading}
              loginErrorMessage
              success={loginSuccess}
              error={loginError}
              showErrorButton
              handleClick={hideLoader}
              errorMessage={loginErrorMessage}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

function mapDispatchToProps(dispatch) {
  return {
    userService: bindActionCreators(userService, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Connexion),
);
