/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import * as currentOrderActions from '../../actions/currentOrderActions';
import * as formatImages from '../../resources/formats';

import CurrentOrder from '../CurrentOrder';
import FormatSelection from '../../components/FormatSelection';
import Layout from '../../components/Layout';

import { getEditionFormat } from '../../api';
import formats from '../../commons/formats.json';

import './style.scss';


class Format extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api: [],
      selectedFormat: null,
    };
  }

  componentWillMount() {
    const { history } = this.props;
    const {
      edition,
      selectedEditionDates,
    } = this.props.currentOrder;
    if (edition === undefined || selectedEditionDates === undefined) {
      history.push('/editions', { nextPage: '/orderRecap' });
    }
  }

  componentDidMount() {
    this.setStateFromStore();
    // eslint-disable-next-line react/destructuring-assignment
    const { orderData } = this.props.currentOrder;

    axios.get(getEditionFormat(orderData.editionId))
      .then((res) => {
        this.setState({
          api: res.data,
        });
      });
  }

  setStateFromStore() {
    const { currentOrder } = this.props;
    const { format } = currentOrder;
    if (!format) return;
    const index = formats.map(f => f.size).indexOf(format.size);
    if (index !== -1) {
      this.setState({ selectedFormat: index });
    }
  }

  handleFormatSelect(index) {
    const { currentOrderActions } = this.props; // eslint-disable-line no-shadow
    const {
      clearCurrentOrderError, setSelectedEditionFormat, setOrderDataFormat, setFormatPrice,
    } = currentOrderActions;
    const { api } = this.state;
    const format = formats[index];
    const getEditionFormatPriceId = api.editionFormatPrices.filter(item => item.format.value === format.size);
    this.setState({
      selectedFormat: index,
    });
    setFormatPrice(getEditionFormatPriceId[0].price);
    setOrderDataFormat(getEditionFormatPriceId[0].id);
    setSelectedEditionFormat(format);
    clearCurrentOrderError();
  }

  render() {
    const pageFormats = formats.filter(format => format.type === 'page');

    const iterableFormats = [
      {
        name: 'formats',
        type: 'page',
        formats: pageFormats,
      },
    ];

    let formatOffset = 0;

    return (
      <Layout
        cover="/assets/images/covers/reservation.jpg"
        title="Réservation"
      >
        <div className="format-container">
          {/* SUBTITLE */}
          <div className="format-subtitle">
            <div className="format-subtitle-content">
          Choisissez le format publicitaire qui vous convient le mieux
          selon les propositions ci-dessous.
            </div>
          </div>

          {/* MAIN CONTENT */}
          <div className="format-main">
            <div className="format-main-content">
              {/* FORMATS */}
              <div className="formats">
                <div className="formats-content">
                  {/* ITERATE OVER JSON */}
                  {iterableFormats.map(({ name, formats }, i) => { // eslint-disable-line no-shadow
                    if (i !== 0) { formatOffset += iterableFormats[i - 1].formats.length; }
                    return (
                      <div className="format-category" key={i}>
                        <div className="format-category-content">
                          <div className="header">{name}</div>
                          <div className="formats-list">
                            {/* Iterate over list of formats for category */}
                            {formats.map(({
                              img, size, height, width,
                            }, i) => { // eslint-disable-line no-shadow
                              const { selectedFormat } = this.state;
                              i += formatOffset; // eslint-disable-line no-param-reassign
                              return (
                                <div key={i} className="format-item">
                                  <FormatSelection
                                    selected={selectedFormat === i}
                                    onSelect={() => this.handleFormatSelect(i)}
                                    image={formatImages[img]}
                                    size={size}
                                    displayText={`${width}mm x ${height}mm`}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Order & Help */}
              <div className="order-help">
                <div className="order-help-content">
                  <div className="order-help-order">
                    <CurrentOrder />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="format-mention">Pour des emplacements préférentiels, contactez-nous via webshop@lavenir.net</p>
        </div>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
});

const mapStateToProps = state => ({
  currentOrder: state.currentOrder,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Format);
