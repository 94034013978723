/* eslint-disable class-methods-use-this */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import LoadingBox from 'components/LoadingBox';
import { getAll, getCurrentOrder } from '../../services/order';
import OrderDropdown from '../../components/OrderDropdown';
import TextButton from '../../components/TextButton';
import s from './style.module.scss';
import * as currentOrderActions from '../../actions/currentOrderActions';

class Orders extends React.Component {
    state = {
      loading: true,
      orders: [],
      selectedOrder: null,
      currentIndex: null,
      selectedOrderLoading: false,
    }

    componentDidMount = async () => {
      try {
        if (this.props.user.isConnected) {
          const data = await getAll(this.props.user.id);
          this.setState({
            loading: false,
            orders: data,
          });
        }
      } catch (error) {
        this.setState({ loading: false });
        console.error(error);
      }
    }

    getId = async (id, index) => {
      const { currentIndex } = this.state;
      this.setState({ selectedOrderLoading: true });
      if (currentIndex === index) {
        this.setState({
          selectedOrder: null,
          currentIndex: null,
          selectedOrderLoading: false,
        });
      } else {
        this.setState({
          currentIndex: index,
        });
        try {
          const data = await getCurrentOrder(id);
          this.setState({
            selectedOrder: data,
            selectedOrderLoading: false,
          });
        } catch (error) {
          console.log(error);
          this.setState({ selectedOrderLoading: true });
        }
      }
    }

    advertisingType(type) {
      switch (type) {
        case 'TBD': return 'Composition';
        case 'RTU': return 'Matériel Fini';
        default: return null;
      }
    }

    displayFile(file) {
      if (file.format === 'application/pdf') {
        return (
          <>
            <object className={s.container__item__pdf} data={file.content} type="application/pdf">
              <embed src={file.content} type="application/pdf" />
            </object>
            {file.name}
          </>
        );
      }
      return (
        <>
          <img
            src={`${file.content}`}
            alt={file.name}
          />
          {file.name}
        </>
      );
    }

    getCommandStatus(param) {
      switch (param) {
        case 'ToBePaid':
          return 'En cours';
        case 'Paid':
          return 'Payé';
        case 'Canceled':
          return 'Annulé';
        default:
          return 'En cours';
      }
    }

    getCommandStatusColor(param) {
      switch (param) {
        case 'ToBePaid':
          return 'black';
        case 'Paid':
          return 'green';
        case 'Canceled':
          return 'red';
        default:
          return 'black';
      }
    }

    render() {
      const {
        loading, orders, selectedOrder, currentIndex, selectedOrderLoading,
      } = this.state;
      const composition = selectedOrder !== null ? selectedOrder.advertising.advertisingType.code === 'TBD' : false;
      const getOrdersList = orders.slice(0).reverse().map((item, index) => (
        <OrderDropdown
          key={index} // eslint-disable-line react/no-array-index-key
          date={`Commandé le ${moment(item.orderDate).format('L')} à ${moment(item.orderDate).format('LT')}`}
          price={`${item.totalPrice} €`}
          status={this.getCommandStatus(item.orderState.state)}
          statusStyle={item.orderState.state === 'ToBePaid' ? 'italic' : 'normal'}
          statusColor={this.getCommandStatusColor(item.orderState.state)}
          getId={() => this.getId(item.id, index)}
          isOpen={index === currentIndex}
        >
          {!selectedOrderLoading && selectedOrder !== null
            ? (
              <div className={s.container}>
                <div className={s.container__item}>
                  <b>Edition: </b>
                  {selectedOrder.editionFormatPrice.editionName}
                </div>
                <div className={s.container__item}>
                  <b>Dates: </b>
                  <ul>
                    {selectedOrder.orderPulicationDates.map((d, i) => (
                      <li key={i}>{moment.unix(d.publicationDate.dateTimeStamp).format('dddd D MMMM YYYY')}</li>
                    ))}
                  </ul>
                </div>
                <div className={s.container__item}>
                  <b>Format: </b>
                  {`${selectedOrder.editionFormatPrice.format.width}mm x ${selectedOrder.editionFormatPrice.format.height}mm (${selectedOrder.editionFormatPrice.format.value})`}
                </div>
                <div className={s.container__item}>
                  <b>Fichier(s): </b>
                  {composition ? selectedOrder.advertising.materialsLinked.length : selectedOrder.advertising.advertisingFinalFile ? 1 : 0}
                </div>
                <div className={s.container__item}>
                  <b>Type: </b>
                  {this.advertisingType(selectedOrder.advertising.advertisingType.code)}
                </div>
                {composition && selectedOrder.advertising.companyOrClientName
                  ? (
                    <div className={s.container__item}>
                      <b>Nom de l&lsquo;activité ou personne: </b>
                      {selectedOrder.advertising.companyOrClientName}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.phoneNumber
                  ? (
                    <div className={s.container__item}>
                      <b>N° de téléphone: </b>
                      {selectedOrder.advertising.phoneNumber}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.email
                  ? (
                    <div className={s.container__item}>
                      <b>E-mail: </b>
                      {selectedOrder.advertising.email}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.websiteLink
                  ? (
                    <div className={s.container__item}>
                      <b>Site web: </b>
                      {selectedOrder.advertising.websiteLink}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.facebookLink
                  ? (
                    <div className={s.container__item}>
                      <b>Facebook: </b>
                      {selectedOrder.advertising.facebookLink}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.openingHours
                  ? (
                    <div className={s.container__item}>
                      <b>Horaires: </b>
                      {selectedOrder.advertising.openingHours}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.address.street
                  ? (
                    <div className={s.container__item}>
                      <b>Rue: </b>
                      {selectedOrder.advertising.address.street}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.address.number
                  ? (
                    <div className={s.container__item}>
                      <b>Numéro: </b>
                      {selectedOrder.advertising.address.number}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.address.municipality.postalCode
                  ? (
                    <div className={s.container__item}>
                      <b>Code postal: </b>
                      {selectedOrder.advertising.address.municipality.postalCode}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.comment
                  ? (
                    <div className={s.container__item}>
                      <b>Commentaires: </b>
                      {selectedOrder.advertising.comment}
                    </div>
                  )
                  : null
                }
                {composition && selectedOrder.advertising.materialsLinked.length > 0
                  ? (
                    <div className={s.container__item}>
                      <b>Annexes: </b>
                      {selectedOrder.advertising.materialsLinked.map((img, i) => (
                        <div className={s.container__item__img} key={i}>
                          {this.displayFile(img)}
                        </div>
                      ))}
                    </div>
                  )
                  : selectedOrder.advertising.advertisingFinalFile && (
                    <div className={s.container__item}>
                      <b>Annexe: </b>
                      <div className={s.container__item__img}>
                        {this.displayFile(selectedOrder.advertising.advertisingFinalFile)}
                      </div>
                    </div>
                  )
                }
                <div className={s.container__buttons}>
                  <TextButton
                    text="Télécharger matériel"
                    handleClick={() => this.props.history.push(`/order/${selectedOrder.id}/material/download`)}
                  />
                  {item.orderState.state === 'ToBePaid'
                    ? (
                      <TextButton
                        text="Payer"
                        handleClick={() => {
                          this.props.currentOrderActions.setSavedOrder(selectedOrder);
                          this.props.history.push('/payment/credit');
                        }}
                      />
                    )
                    : null
                }
                </div>
              </div>
            )
            : <LoadingBox loading />
        }

        </OrderDropdown>
      ));
      if (loading) {
        return <div>Chargement de vos commandes...</div>;
      }
      if (orders.length > 0) {
        return (
          <>
            { getOrdersList }
          </>
        );
      }
      return (
        <>
          <div className={s.container__noorder}>Vous n&apos;avez encore rien commandé</div>
          <TextButton
            text="Passer commande"
            handleClick={() => this.props.history.push('/editions')}
          />
        </>
      );
    }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Orders));
