export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER';

export const SET_USER_READY = 'SET_USER_READY';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';

export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';

export const SET_INVOICE_DEMAND = 'SET_INVOICE_DEMAND';
export const SET_GENERAL_CONDITIONS_ACCEPTED = 'SET_GENERAL_CONDITIONS_ACCEPTED';

export const LOAD_EDITIONS_SUCCESS = 'LOAD_EDITIONS_SUCCESS';
export const SET_EDITION = 'SET_EDITION';
export const SET_FROM_PRICE = 'SET_FROM_PRICE';
export const SET_ORDER_DATA_EDITION = 'SET_ORDER_DATA_EDITION';
export const SET_SELECTED_EDITION_DATES = 'SET_SELECTED_EDITION_DATES';
export const SET_ORDER_DATA_EDITION_DATES = 'SET_ORDER_DATA_EDITION_DATES';
export const SET_FORMAT = 'SET_FORMAT';
export const SET_ORDER_DATA_FORMAT = 'SET_ORDER_DATA_FORMAT';
export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE';
export const SET_REGION = 'SET_REGION';
export const RESET_ORDER_REDUCER = 'RESET_ORDER_REDUCER';
export const SET_SAVED_ORDER = 'SET_SAVED_ORDER';

export const SET_ORDER_ERROR = 'SET_ORDER_ERROR';
export const CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR';

export const SET_MATERIAL_TYPE = 'SET_MATERIAL_TYPE';
export const SET_MATERIAL_FILES = 'SET_MATERIAL_FILES';

export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';

export const SET_FORMAT_PRICE = 'SET_FORMAT_PRICE';
