/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as deviceActions from '../../actions/deviceActions';

class DeviceDetection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth, device: 'mobile' }; // eslint-disable-line react/no-unused-state
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const { width } = this.state;
    const { deviceDetection } = this.props;
    this.setState({ width: window.innerWidth });

    const isMobile = width <= 425;
    const isTablet = width > 425 && width <= 768;

    deviceDetection.setDeviceType(
      isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop',
    );
  }

  render() {
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deviceDetection: bindActionCreators(deviceActions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(DeviceDetection);
