import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class TextInputSearch extends React.Component {
  style = {
    display: 'flex',
    flexAlign: 'column',
  };

  render() {
    const {
      noErrorMessages,
      value,
      type,
      name,
      placeHolder,
      errorMessage,
      valid,
      formSubmitedOnce,
      clickHandler,
      required,
      handleChange,
    } = this.props;
    const err = !valid && formSubmitedOnce;
    return (
      <div className="search-input-container">
        <div className="input-container">
          <input
            onClick={clickHandler ? () => clickHandler() : null}
            autoComplete="off"
            className={`${err ? 'text-input-err' : null}`}
            value={value || ''}
            type={type || 'text'}
            placeholder={placeHolder}
            name={name}
            onChange={e => handleChange({
              name: e.target.name,
              value: e.target.value,
              required,
            })
            }
          />
          {noErrorMessages ? null : (
            <div className="text-input-error-placeholder">
              {valid ? null : (
                <div className="text-input-error">{errorMessage}</div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

TextInputSearch.propTypes = {
  clickHandler: PropTypes.func,
  handleChange: PropTypes.func,
  placeHolder: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  noErrorMessages: PropTypes.bool,
  value: PropTypes.string,
  formSubmitedOnce: PropTypes.bool,
  valid: PropTypes.bool,
  type: PropTypes.string,
};

export default TextInputSearch;
