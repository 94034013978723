/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { endpoints } from 'api';
import LoadingBox from 'components/LoadingBox';
import TextButton from '../TextButton';
import RenderField from '../RenderField';

import { validate } from '../../helpers/validate';

// eslint-disable-next-line import/no-mutable-exports
let UserForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    zipCodeValue,
    hidden,
    handleCancel,
    change,
    municipalityValue,
    initialValues,
    type,
    showErrors,
  } = props;

  const [municipalities, setMunicipalities] = useState([]);

  useEffect(() => {
    let didCancel = false;
    const fetchMunicipalities = async () => {
      try {
        const result = await axios.get(endpoints.getMunicipalities);
        if (!didCancel) {
          setMunicipalities(result.data);
        }
      } catch (error) {
        if (!didCancel) {
          console.log(error);
        }
      }
    };

    fetchMunicipalities();
    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    let didCancel = false;
    if (municipalityValue !== undefined && initialValues.address.postalCode !== zipCodeValue && !didCancel) {
      change('address.municipalityId', null);
    }
    return () => {
      didCancel = true;
    };
  }, [zipCodeValue]);

  const getCities = (param) => {
    const foundMuni = municipalities.filter(city => city.postalCode === Number(param));
    foundMuni.unshift({ name: 'Choisissez une ville', id: '' });
    return foundMuni;
  };

  return municipalities.length ? (
    <form autoComplete="off" className={hidden ? 'form-hidden' : ''} onSubmit={handleSubmit}>
      <div className="signUp-container--row">
        <Field
          name="firstName"
          type="text"
          component={RenderField}
          placeholder="Jean"
          label="Prénom"
          showErrors={showErrors}
        />
        <Field
          name="lastName"
          type="text"
          component={RenderField}
          placeholder="Dupont"
          label="Nom"
          showErrors={showErrors}
        />
      </div>
      <Field
        name="mail"
        type="mail"
        component={RenderField}
        placeholder="jeandupont@gmail.com"
        label="E-mail"
        showErrors={showErrors}
      />
      {type !== 'personalInfo' && (
        <Field
          name="password"
          type="password"
          component={RenderField}
          placeholder="Mot de passe"
          label="Mot de passe"
          showErrors={showErrors}
        />
      )}
      <Field
        name="companyName"
        type="text"
        component={RenderField}
        placeholder="Nom de l'entreprise"
        label="Nom de l'entreprise"
        showErrors={showErrors}
      />
      <Field
        name="companyType"
        component={RenderField}
        type="select"
        placeholder=""
        label="Type d'entreprise"
        options={[
          { name: 'Choisissez un type d\'entreprise', id: '' },
          { name: 'Personne physique', id: 'personne physique' },
          { name: 'Société simple', id: 'simple' },
          { name: 'Société en nom collectif', id: 'collectif' },
          { name: 'Société en commandite (simple)', id: 'commandite' },
          { name: 'Société à responsabilité limitée', id: 'limite' },
          { name: 'Société coopérative (à responsabilité limitée)', id: 'cooperative' },
          { name: 'Société anonyme', id: 'anonyme' },
        ]}
        showErrors={showErrors}
      />
      {type === 'personalInfo' && (
        <Field
          name="companyVAT"
          type="text"
          component={RenderField}
          placeholder="Numéro de TVA"
          label="Numéro de TVA"
          showErrors={showErrors}
        />
      )}
      <Field
        name="phone"
        type="number"
        component={RenderField}
        placeholder="0400 00 00 00"
        label="Téléphone"
        showErrors={showErrors}
      />
      <div className="signUp-container--row_3-4">
        <Field
          name="address.street"
          type="text"
          component={RenderField}
          placeholder="Rue du paradis"
          label="Rue"
          showErrors={showErrors}
        />
        <Field
          name="address.number"
          type="number"
          component={RenderField}
          placeholder="31"
          label="N°"
          showErrors={showErrors}
        />
      </div>
      <div className="signUp-container--row">
        <Field
          name="address.postalCode"
          type="number"
          component={RenderField}
          placeholder="5000"
          label="Code postal"
          showErrors={showErrors}
        />
        <Field
          name="address.municipalityId"
          component={RenderField}
          type="select"
          placeholder="Namur"
          label="Ville"
          options={getCities(zipCodeValue)}
          showErrors={showErrors}
        />
      </div>
      <div className="signUp-buttons--row">
        {type !== 'personalInfo' && (
        <TextButton
          disabled={pristine || submitting}
          onClick={reset}
          color="pink"
          text="Annuler"
          handleClick={handleCancel}
        />
        )}
        <TextButton
          text={type === 'personalInfo' ? 'Modifier' : 'Inscription'}
          type="submit"
          handleClick={handleSubmit}
        />
      </div>
    </form>
  ) : (<LoadingBox />);
};

UserForm = withRouter(reduxForm({
  form: 'userInfo',
  validate,
})(UserForm));

const selector = formValueSelector('userInfo');

UserForm = connect((state) => {
  const zipCodeValue = selector(state, 'address.postalCode');
  const municipalityValue = selector(state, 'address.municipalityId');
  return {
    zipCodeValue,
    municipalityValue,
    initialValues: state.user,
  };
})(UserForm);

export default UserForm;
