/* eslint-disable react/prop-types */
import React from 'react';

import s from './style.module.scss';

const InputOnBlur = (props) => {
  const {
    name,
    value,
    error,
    onChange,
    onClick,
    onBlur,
    textarea,
  } = props;
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={name} className={s.label}>
      {name}
      {textarea
        ? (
          <textarea
            onChange={onChange}
            value={value}
            onBlur={onBlur}
          />
        ) : (
          <input
            autoComplete="nope"
            type="text"
            onChange={onChange}
            onClick={onClick}
            value={value}
            onBlur={onBlur}
          />
        )
      }
      {error ? <span>Ce champs est requis</span> : ''}
    </label>
  );
};

export default InputOnBlur;
