/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ForgetPasswordForm from '../../components/ForgetPasswordForm';
import LoadingBox from '../../components/LoadingBox';
import Layout from '../../components/Layout';

import '../SignUp/style.scss';

import * as userService from '../../services/user';


const ForgetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const onFormSubmit = async (values) => {
    setLoading(true);
    try {
      await userService.resetPassword(values.mail);
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(`Si un compte existe à l'e-mail "${values.mail}", un nouveau mot-de-passe sera envoyé à cette adresse.`);
    } catch (error) {
      setLoading(false);
      setError(true);
      setErrorMessage(error.message);
    }
  };

  const hideLoader = () => {
    setSuccess(false);
    setError(false);
    setErrorMessage('');
  };

  return (
    <Layout
      cover="/assets/images/covers/reservation.jpg"
      isHome
    >
      <div className="signUp-container">
        <div className="signUp-container--header">Mot-de-passe oublié</div>
        {successMessage !== '' && (
          <span className="signUp-container--message">
            {successMessage}
          </span>
        )}
        <ForgetPasswordForm hidden={loading} onSubmit={onFormSubmit} />
        {loading && (
          <div className="loading-container">
            <LoadingBox
              loading={loading}
              success={success}
              error={error}
              showErrorButton
              handleClick={hideLoader}
              errorMessage={errorMessage}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(ForgetPassword),
);
