/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { CircleSpinner } from 'react-spinners-kit';

import ErrorBox from '../ErrorBox';

import './style.scss';

class LoadingBox extends React.Component {
  handleClick() {
    const { handleClick } = this.props;
    if (handleClick) handleClick();
  }

  render() {
    const {
      loading,
      success,
      error,
      errorMessage,
      showErrorIcon,
      showErrorButton,
    } = this.props;

    return (
      <div className="loading-box">
        {loading ? (
          <div className="loading-spinner">
            <CircleSpinner size={30} color="#686769" loading={loading} />
          </div>
        ) : success ? (
          <div className="loading-success">
            <FaCheckCircle />
          </div>
        ) : error ? (
          <ErrorBox
            showIcon={showErrorIcon}
            showButton={showErrorButton}
            handleClick={() => this.handleClick()}
            errorMessage={errorMessage}
          />
        ) : null}
      </div>
    );
  }
}

export default LoadingBox;
